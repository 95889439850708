var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "shadow sm:rounded-md sm:overflow-hidden" }, [
        _c(
          "div",
          {
            staticClass:
              "flex px-4 py-5 bg-white border-b border-gray-200 sm:p-6"
          },
          [
            _c("h3", { staticClass: "text-lg font-medium text-gray-900" }, [
              _vm._v(" " + _vm._s(_vm.$t("tenant.page.customers.title")) + " ")
            ]),
            _c("v-spacer"),
            !_vm.isViewer ? _c("UserDialog", { staticClass: "mr-2" }) : _vm._e()
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "pb-5 space-y-6 bg-white" },
          [
            _c("v-data-table", {
              attrs: {
                loading: _vm.getLoadingDistributors,
                "loading-text": _vm.$t("tenant.page.customers.progress"),
                headers: _vm.headers,
                items: _vm.getDistributors,
                "items-per-page": 30,
                flat: ""
              },
              scopedSlots: _vm._u([
                {
                  key: "item.number_of_subscriptions",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _vm._v(
                        " " + _vm._s(_vm.$n(item.number_of_subscriptions)) + " "
                      )
                    ]
                  }
                },
                {
                  key: "item.action",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c(
                        "v-btn",
                        {
                          staticClass: "buttonMod",
                          attrs: { text: "", small: "", color: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.showSubs(item)
                            }
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "tenant.page.customers.headers.viewsubscriptions"
                                )
                              ) +
                              " "
                          )
                        ]
                      )
                    ]
                  }
                }
              ])
            })
          ],
          1
        )
      ]),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "700" },
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "headline" },
                [
                  _c(
                    "v-row",
                    [
                      _c("v-col", { attrs: { cols: "7" } }, [
                        _c("h4", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "tenant.page.customers.subscriptions.title"
                                )
                              ) +
                              " "
                          )
                        ])
                      ]),
                      _c("v-spacer"),
                      _c(
                        "v-col",
                        { attrs: { cols: "5" } },
                        [
                          !_vm.isViewer
                            ? _c(
                                "v-btn",
                                {
                                  attrs: { color: "primary" },
                                  on: { click: _vm.showAddDiag }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "tenant.page.customers.subscriptions.add"
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-list",
                    { attrs: { dense: "" } },
                    [
                      _c("v-subheader", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "tenant.page.customers.subscriptions.products"
                              )
                            ) +
                            " "
                        )
                      ]),
                      _vm._l(_vm.selectedCustomer.subscriptions, function(
                        item,
                        i
                      ) {
                        return _c(
                          "v-list-item",
                          { key: i },
                          [
                            _c(
                              "v-list-item-content",
                              [
                                _c("v-list-item-title", [
                                  _vm._v(_vm._s(item.product_name))
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        )
                      })
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "320" },
          model: {
            value: _vm.subDialog,
            callback: function($$v) {
              _vm.subDialog = $$v
            },
            expression: "subDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-text",
                [
                  _c(
                    "v-row",
                    [
                      _c("v-col", [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "tenant.page.customers.subscriptions.addproduct"
                              )
                            )
                          )
                        ])
                      ])
                    ],
                    1
                  ),
                  _c("v-select", {
                    attrs: { items: _vm.getProducts, "item-text": "name" },
                    on: { change: _vm.newSubChange }
                  }),
                  _c("small", [
                    _vm._v(
                      _vm._s(_vm.$t("tenant.page.customers.subscriptions.hint"))
                    )
                  ])
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "blue darken-1", outlined: "" },
                      on: { click: _vm.addSubscriptionCloseDialog }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("tenant.page.customers.subscriptions.add")
                          ) +
                          " "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }