var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ma-4", attrs: { app: "" } },
    [
      _c(
        "v-container",
        { staticClass: "full-height", attrs: { fluid: "" } },
        [
          _c("v-row", [_c("DataRow")], 1),
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { md: "4" } }, [
                _c(
                  "div",
                  { staticClass: "shadow sm:rounded-md sm:overflow-hidden" },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "px-4 py-5 space-y-6 bg-white border-b border-gray-200 sm:p-6"
                      },
                      [
                        _c(
                          "h3",
                          { staticClass: "text-lg font-medium text-gray-900" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "tenant.page.dashboard.customers.title"
                                  )
                                ) +
                                " "
                            )
                          ]
                        )
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "pb-5 space-y-6 bg-white" },
                      [_c("CustomerTable")],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "px-4 py-3 text-right bg-gray-50 sm:px-6"
                      },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass:
                              "font-semibold text-teal-500 transition duration-150 ease-in-out hover:text-teal-600",
                            attrs: { to: "distributors" }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("tenant.page.dashboard.customers.more")
                                ) +
                                " "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ]
                )
              ]),
              _c("v-col", { attrs: { md: "4" } }, [
                _c(
                  "div",
                  { staticClass: "shadow sm:rounded-md sm:overflow-hidden" },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "px-4 py-5 space-y-6 bg-white border-b border-gray-200 sm:p-6"
                      },
                      [
                        _c(
                          "h3",
                          { staticClass: "text-lg font-medium text-gray-900" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("tenant.page.dashboard.products.title")
                                ) +
                                " "
                            )
                          ]
                        )
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "pb-5 space-y-6 bg-white" },
                      [_c("ProductsTable")],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "px-4 py-3 text-right bg-gray-50 sm:px-6"
                      },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass:
                              "font-semibold text-teal-500 transition duration-150 ease-in-out hover:text-teal-600",
                            attrs: { to: "products" }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("tenant.page.dashboard.products.more")
                                ) +
                                " "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ]
                )
              ]),
              _c("v-col", { attrs: { md: "4" } }, [
                _c(
                  "div",
                  { staticClass: "shadow sm:rounded-md sm:overflow-hidden" },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "px-4 py-5 space-y-6 bg-white border-b border-gray-200 sm:p-6"
                      },
                      [
                        _c(
                          "h3",
                          { staticClass: "text-lg font-medium text-gray-900" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("tenant.page.dashboard.data.title")
                                ) +
                                " "
                            )
                          ]
                        )
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "pb-5 space-y-6 bg-white" },
                      [_c("SessionsTable")],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "px-4 py-3 text-right bg-gray-50 sm:px-6"
                      },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass:
                              "font-semibold text-teal-500 transition duration-150 ease-in-out hover:text-teal-600",
                            attrs: { to: "data" }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("tenant.page.dashboard.data.more")
                                ) +
                                " "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ]
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }