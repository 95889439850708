<template>
  <div class="ma-4" app>
    <h1 class="text-2xl">Api keys</h1>

    <div class="flex justify-between my-4" v-if="getApiKey">
      <div class="w-2/3 space-y-1">
        <span class="block w-full p-2 bg-white border rounded">
          {{ getApiKey }}
        </span>
        <v-progress-linear
          indeterminate
          color="primary"
          v-if="getApiKeyChanging"
        ></v-progress-linear>
      </div>
      <v-btn color="primary" @click.stop.prevent="refreshApiKey" v-if="isAdmin">
        {{ $t("common.page.api.refreshapikey") }}
      </v-btn>
    </div>
    <div class="flex justify-between my-4" v-else>
      <div class="w-2/3 space-y-1">
        <span class="block w-full p-2 bg-white border rounded">
          {{ $t("common.page.api.noapikey") }}
        </span>
        <v-progress-linear
          indeterminate
          color="primary"
          v-if="getApiKeyChanging"
        ></v-progress-linear>
      </div>
      <v-btn color="primary" @click.stop.prevent="createApiKey" v-if="isAdmin">
        {{ $t("common.page.api.createapikey") }}
      </v-btn>
    </div>

    <h2 class="mb-4 text-xl">List of API's</h2>

    <v-expansion-panels accordion>
      <v-expansion-panel v-for="(api, apiIndex) in apis" v-bind:key="apiIndex">
        <v-expansion-panel-header>
          <h3 class="text-lg">{{ api.title }}</h3>
        </v-expansion-panel-header>

        <v-expansion-panel-content>
          <h4 class="px-2 my-2 mt-5 text-lg font-medium">Endpoint</h4>
          <span class="px-4 py-2 bg-white">{{ api.url }}</span>

          <h4 class="px-2 my-2 mt-5 text-lg font-medium">Headers</h4>

          <div class="overflow-hidden border-b border-gray-200">
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase "
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase "
                  >
                    Required
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase "
                  >
                    Description
                  </th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr
                  v-for="(header, headerIndex) in api.headers"
                  v-bind:key="headerIndex"
                >
                  <td class="px-6 py-4 whitespace-nowrap">{{ header.name }}</td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    {{ header.required }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    {{ header.description }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <h4 class="px-2 my-2 mt-5 text-lg font-medium">Request params</h4>

          <div class="overflow-hidden border-b border-gray-200">
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase "
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase "
                  >
                    Required
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase "
                  >
                    Description
                  </th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr
                  v-for="(header, headerIndex) in api.requestParams"
                  v-bind:key="headerIndex"
                >
                  <td class="px-6 py-4 whitespace-nowrap">{{ header.name }}</td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    {{ header.required }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    {{ header.description }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <h4 class="px-2 my-2 mt-5 text-lg font-medium">Example Response</h4>
          <pre class="font-mono">
                        {{ JSON.stringify(api.response, undefined, 2) }}
            </pre
          >
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      apis: [
        {
          url: `${process.env.VUE_APP_API_URL}/api_session_data`,
          title: "Get a single session data",
          headers: [
            {
              name: "Authorization",
              required: "Yes",
              description: "Contains the API key. Written as 'Basic API_KEY'",
            },
          ],
          requestParams: [
            {
              name: "session_id",
              required: "Yes",
              description:
                "session_id of session whose data is requested. Added as a URL query parameter",
            },
          ],
          response: {
            bmm_dex_submitted_via: "api_submission",
            failed_validation_document_count: 1,
            total_document_count: 3,
            bmm_dex_event_type: "enrolment",
            bmm_dex_created_at: "2021-03-08 11:53:43",
            bmm_dex_state: "failed_validation",
            session_documents: [
              {
                bmm_dex_created_at: "2021-03-08 11:53:43",
                origin_uid: 2,
                name: "Kahwi Leonard",
                scope_id: 5,
              },
              {
                origin_uid: 6,
                bmm_dex_created_at: "2021-03-08 11:53:43",
                name: "Dennis Mitchel",
                scope_id: 13,
              },
              {
                bmm_dex_created_at: "2021-03-08 11:53:43",
                scope_id: 12,
                origin_uid: 5,
                name: "Nikola Jokic",
              },
            ],
          },
        },
        {
          url: `${process.env.VUE_APP_API_URL}/api_sessions_list`,
          title: "Get list of sessions not yet downloaded",
          headers: [
            {
              name: "Authorization",
              required: "Yes",
              description: "Contains the API key. Written as 'Basic API_KEY'",
            },
          ],
          requestParams: [
            {
              name: "before",
              required: "No",
              description:
                "Pagination data to show sessions before session with this id",
            },
            {
              name: "after",
              required: "No",
              description:
                "Pagination data to show sessions after session with this id",
            },
          ],
          response: {
            data: [
              {
                total_document_count: 3,
                downloaded: false,
                created_at: "2021-05-12T21:47:00+00:00",
                updated_at: "2021-05-12T21:47:00+00:00",
                event_type: "enrolment",
                session_id: "nemDgxmewV1lsY6FZISXI",
                customer_name: "Customer 2",
                customer_identifier: null,
                product_name: "Test Simple Validations TXT",
                product_identifier: null,
              },
            ],
            pagination: {},
          },
        },
      ],
    };
  },
  mounted() {
    this.$store.dispatch("fetchApiKey");
  },
  computed: { ...mapGetters(["getApiKey", "getApiKeyChanging", "isAdmin"]) },
  methods: {
    createApiKey() {
      console.log("Creating api key");
      this.$store.dispatch("createApiKey");
    },
    refreshApiKey() {
      this.$store.dispatch("refreshApiKey");
    },
  },
};
</script>