import axios from "axios";

export default {
  state: {
    distributors: [],
    loadingDistributors: false
  },
  actions: {
    getDistributors({ commit, state, rootState }) {
      commit("setLoadingDistributors", true);
      const user = rootState.currentUser;
      const tenantId = rootState.tenantId;
      var url =
        `${process.env.VUE_APP_API_URL}/get_tenant_customers`;
      user
        .getIdToken(true)
        .then(async function (token) {
          const headers = {
            Authorization: token.toString(),
            "Content-Type": "application/json"
          };
          var hosturl = window.location.host;
          var tenant = hosturl.split(".")[0];
          var subdomain = hosturl.split(".")[0];
          url = url + "?tenant=" + tenant;
          url = url + "&tenant_id=" + tenantId;
          url = url + "&subdomain=" + subdomain;

          console.log(url);
          const response = await axios.get(url, {
            headers: headers
          });
          console.log("Distributors");
          console.log(response.data);
          commit("setDistributors", response.data);
          console.log(state.distributors);
        })
        .catch(error => {
          console.log(error);
          // TODO: ??
          commit("set", []);
        });
    },
    createDistributor({ dispatch, commit, rootState, rootGetters }, payload) {
      if (!rootGetters.isViewer) {
        commit("setLoadingDistributors", true);
        if (rootState.tenantId != "admin") {
          payload.tenant_id = rootState.tenantId;
          var hosturl = window.location.host;
          payload.tenant = hosturl.split(".")[0];
        }
        console.log("Creating tenant_customer");
        console.log(payload);
        const url = `${process.env.VUE_APP_API_URL}/create_tenant_customer`;
        const user = rootState.currentUser;
        user
          .getIdToken(true)
          .then(async function (token) {
            const headers = {
              Authorization: token.toString(),
              "Content-Type": "application/json",
              Accept: "application/json"
            };
            const response = await axios.post(url, payload, {
              headers: headers
            });
            if (response.status == "200") {
              dispatch("getDistributors");
              console.log("Data Passed");
            }
          })
          .catch(error => {
            console.log(error);
          });
      }
    },
    createDistributorSubscription({ dispatch, commit, rootState, rootGetters }, payload) {
      if (!rootGetters.isViewer) {
        commit("setLoadingDistributors", true);
        payload.bmm_dex_tenant_id = rootState.tenantId;
        var hosturl = window.location.host;
        var tenant = hosturl.split(".")[0];
        payload.tenant = tenant;
        console.log(payload);
        const url = `${process.env.VUE_APP_API_URL}/create_tenant_customer_subscription`;
        const user = rootState.currentUser;
        user
          .getIdToken(true)
          .then(async function (token) {
            const headers = {
              Authorization: token.toString(),
              "Content-Type": "application/json",
              Accept: "application/json"
            };
            const response = await axios.post(url, payload, {
              headers: headers
            });
            if (response.status == "200") {
              dispatch("getDistributors");
              console.log("Pairing Created");
            }
          })
          .catch(error => {
            console.log(error);
          });
      }
    }
  },
  mutations: {
    setDistributors(state, payload) {
      state.distributors = payload;
      state.loadingDistributors = false;
    },
    setLoadingDistributors(state, payload) {
      state.loadingDistributors = payload;
    }
  },
  getters: {
    getDistributors: state => state.distributors,
    getLoadingDistributors: state => state.loadingDistributors
  }
};
