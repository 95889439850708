<template>
  <div class="flex px-1 py-5">
    <div class="w-full md:flex">
      <div class="w-full px-2 md:w-1/3">
        <div class="mb-4 rounded-lg shadow-sm">
          <div class="relative overflow-hidden bg-white rounded-lg shadow">
            <div class="relative z-10 px-3 pt-8 pb-10 text-center">
              <h4 class="text-sm leading-tight text-gray-500 uppercase">
                {{ $t("tenant.page.dashboard.summary.sessions") }}
              </h4>
              <h3
                class="my-3 text-3xl font-semibold leading-tight text-gray-700"
              >
                {{ $n(getDashboardData["session_count"]) }}
              </h3>
            </div>
            <div class="absolute inset-x-0 bottom-0">
              <canvas id="chart1" height="70"></canvas>
            </div>
          </div>
        </div>
      </div>
      <div class="w-full px-2 md:w-1/3">
        <div class="mb-4 rounded-lg shadow-sm">
          <div class="relative overflow-hidden bg-white rounded-lg shadow">
            <div class="relative z-10 px-3 pt-8 pb-10 text-center">
              <h4 class="text-sm leading-tight text-gray-500 uppercase">
                {{ $t("tenant.page.dashboard.summary.subscriptions") }}
              </h4>
              <h3
                class="my-3 text-3xl font-semibold leading-tight text-gray-700"
              >
                {{ $n(getDashboardData["subscription_count"]) }}
              </h3>
            </div>
            <div class="absolute inset-x-0 bottom-0">
              <canvas id="chart2" height="70"></canvas>
            </div>
          </div>
        </div>
      </div>
      <div class="w-full px-2 md:w-1/3">
        <div class="mb-4 rounded-lg shadow-sm">
          <div class="relative overflow-hidden bg-white rounded-lg shadow">
            <div class="relative z-10 px-3 pt-8 pb-10 text-center">
              <h4 class="text-sm leading-tight text-gray-500 uppercase">
                {{ $t("tenant.page.dashboard.summary.customers") }}
              </h4>
              <h3
                class="my-3 text-3xl font-semibold leading-tight text-gray-700"
              >
                {{ $n(getDashboardData["customer_count"]) }}
              </h3>
            </div>
            <div class="absolute inset-x-0 bottom-0">
              <canvas id="chart3" height="70"></canvas>
            </div>
          </div>
        </div>
      </div>

      <div class="w-full px-2 md:w-1/3">
        <div class="mb-4 rounded-lg shadow-sm">
          <div class="relative overflow-hidden bg-white rounded-lg shadow">
            <div class="relative z-10 px-3 pt-8 pb-10 text-center">
              <h4 class="text-sm leading-tight text-gray-500 uppercase">
                {{ $t("tenant.page.dashboard.summary.products") }}
              </h4>
              <h3
                class="my-3 text-3xl font-semibold leading-tight text-gray-700"
              >
                {{ $n(getDashboardData["product_count"]) }}
              </h3>
            </div>
            <div class="absolute inset-x-0 bottom-0">
              <canvas id="chart3" height="70"></canvas>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {},
  computed: {
    ...mapGetters(["getDashboardData"]),
  },
};
</script>
