var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ma-4", attrs: { app: "" } },
    [
      _c("h1", { staticClass: "text-2xl" }, [_vm._v("Api keys")]),
      _vm.getApiKey
        ? _c(
            "div",
            { staticClass: "flex justify-between my-4" },
            [
              _c(
                "div",
                { staticClass: "w-2/3 space-y-1" },
                [
                  _c(
                    "span",
                    { staticClass: "block w-full p-2 bg-white border rounded" },
                    [_vm._v(" " + _vm._s(_vm.getApiKey) + " ")]
                  ),
                  _vm.getApiKeyChanging
                    ? _c("v-progress-linear", {
                        attrs: { indeterminate: "", color: "primary" }
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm.isAdmin
                ? _c(
                    "v-btn",
                    {
                      attrs: { color: "primary" },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          return _vm.refreshApiKey($event)
                        }
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("common.page.api.refreshapikey")) +
                          " "
                      )
                    ]
                  )
                : _vm._e()
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "flex justify-between my-4" },
            [
              _c(
                "div",
                { staticClass: "w-2/3 space-y-1" },
                [
                  _c(
                    "span",
                    { staticClass: "block w-full p-2 bg-white border rounded" },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("common.page.api.noapikey")) + " "
                      )
                    ]
                  ),
                  _vm.getApiKeyChanging
                    ? _c("v-progress-linear", {
                        attrs: { indeterminate: "", color: "primary" }
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm.isAdmin
                ? _c(
                    "v-btn",
                    {
                      attrs: { color: "primary" },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          return _vm.createApiKey($event)
                        }
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("common.page.api.createapikey")) +
                          " "
                      )
                    ]
                  )
                : _vm._e()
            ],
            1
          ),
      _c("h2", { staticClass: "mb-4 text-xl" }, [_vm._v("List of API's")]),
      _c(
        "v-expansion-panels",
        { attrs: { accordion: "" } },
        _vm._l(_vm.apis, function(api, apiIndex) {
          return _c(
            "v-expansion-panel",
            { key: apiIndex },
            [
              _c("v-expansion-panel-header", [
                _c("h3", { staticClass: "text-lg" }, [
                  _vm._v(_vm._s(api.title))
                ])
              ]),
              _c("v-expansion-panel-content", [
                _c(
                  "h4",
                  { staticClass: "px-2 my-2 mt-5 text-lg font-medium" },
                  [_vm._v("Endpoint")]
                ),
                _c("span", { staticClass: "px-4 py-2 bg-white" }, [
                  _vm._v(_vm._s(api.url))
                ]),
                _c(
                  "h4",
                  { staticClass: "px-2 my-2 mt-5 text-lg font-medium" },
                  [_vm._v("Headers")]
                ),
                _c(
                  "div",
                  { staticClass: "overflow-hidden border-b border-gray-200" },
                  [
                    _c(
                      "table",
                      { staticClass: "min-w-full divide-y divide-gray-200" },
                      [
                        _c("thead", { staticClass: "bg-gray-50" }, [
                          _c("tr", [
                            _c(
                              "th",
                              {
                                staticClass:
                                  "px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase ",
                                attrs: { scope: "col" }
                              },
                              [_vm._v(" Name ")]
                            ),
                            _c(
                              "th",
                              {
                                staticClass:
                                  "px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase ",
                                attrs: { scope: "col" }
                              },
                              [_vm._v(" Required ")]
                            ),
                            _c(
                              "th",
                              {
                                staticClass:
                                  "px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase ",
                                attrs: { scope: "col" }
                              },
                              [_vm._v(" Description ")]
                            )
                          ])
                        ]),
                        _c(
                          "tbody",
                          { staticClass: "bg-white divide-y divide-gray-200" },
                          _vm._l(api.headers, function(header, headerIndex) {
                            return _c("tr", { key: headerIndex }, [
                              _c(
                                "td",
                                { staticClass: "px-6 py-4 whitespace-nowrap" },
                                [_vm._v(_vm._s(header.name))]
                              ),
                              _c(
                                "td",
                                { staticClass: "px-6 py-4 whitespace-nowrap" },
                                [_vm._v(" " + _vm._s(header.required) + " ")]
                              ),
                              _c(
                                "td",
                                { staticClass: "px-6 py-4 whitespace-nowrap" },
                                [_vm._v(" " + _vm._s(header.description) + " ")]
                              )
                            ])
                          }),
                          0
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "h4",
                  { staticClass: "px-2 my-2 mt-5 text-lg font-medium" },
                  [_vm._v("Request params")]
                ),
                _c(
                  "div",
                  { staticClass: "overflow-hidden border-b border-gray-200" },
                  [
                    _c(
                      "table",
                      { staticClass: "min-w-full divide-y divide-gray-200" },
                      [
                        _c("thead", { staticClass: "bg-gray-50" }, [
                          _c("tr", [
                            _c(
                              "th",
                              {
                                staticClass:
                                  "px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase ",
                                attrs: { scope: "col" }
                              },
                              [_vm._v(" Name ")]
                            ),
                            _c(
                              "th",
                              {
                                staticClass:
                                  "px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase ",
                                attrs: { scope: "col" }
                              },
                              [_vm._v(" Required ")]
                            ),
                            _c(
                              "th",
                              {
                                staticClass:
                                  "px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase ",
                                attrs: { scope: "col" }
                              },
                              [_vm._v(" Description ")]
                            )
                          ])
                        ]),
                        _c(
                          "tbody",
                          { staticClass: "bg-white divide-y divide-gray-200" },
                          _vm._l(api.requestParams, function(
                            header,
                            headerIndex
                          ) {
                            return _c("tr", { key: headerIndex }, [
                              _c(
                                "td",
                                { staticClass: "px-6 py-4 whitespace-nowrap" },
                                [_vm._v(_vm._s(header.name))]
                              ),
                              _c(
                                "td",
                                { staticClass: "px-6 py-4 whitespace-nowrap" },
                                [_vm._v(" " + _vm._s(header.required) + " ")]
                              ),
                              _c(
                                "td",
                                { staticClass: "px-6 py-4 whitespace-nowrap" },
                                [_vm._v(" " + _vm._s(header.description) + " ")]
                              )
                            ])
                          }),
                          0
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "h4",
                  { staticClass: "px-2 my-2 mt-5 text-lg font-medium" },
                  [_vm._v("Example Response")]
                ),
                _c("pre", { staticClass: "font-mono" }, [
                  _vm._v(
                    "                      " +
                      _vm._s(JSON.stringify(api.response, undefined, 2)) +
                      "\n          "
                  )
                ])
              ])
            ],
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }