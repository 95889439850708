var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { sm: "12" } }, [
            _c(
              "div",
              { staticClass: "sm:overflow-hidden" },
              [
                _c("div", { staticClass: "flex justify-between px-4" }, [
                  _c(
                    "h3",
                    { staticClass: "text-lg font-medium text-gray-900" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("tenant.page.products.details")) +
                          " "
                      )
                    ]
                  ),
                  !_vm.isViewer
                    ? _c(
                        "div",
                        [
                          _vm.getShowEditForm
                            ? _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "blue-grey lighten-2",
                                    outlined: ""
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.closeEdit()
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("tenant.page.products.cancel")
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            : _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "blue-grey lighten-2",
                                    outlined: ""
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.showEdit()
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("tenant.page.products.edit")
                                      ) +
                                      " "
                                  )
                                ]
                              )
                        ],
                        1
                      )
                    : _vm._e()
                ]),
                _vm.getShowEditForm
                  ? _c("product-edit", {
                      attrs: { product: this.getProductDetails }
                    })
                  : _c("product-view", {
                      attrs: { product: this.getProductDetails }
                    })
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }