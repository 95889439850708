var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("table", { staticClass: "min-w-full divide-y divide-gray-200" }, [
    _c("thead", { staticClass: "bg-gray-50" }, [
      _c("tr", [
        _c(
          "th",
          {
            staticClass:
              "px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase",
            attrs: { scope: "col" }
          },
          [
            _vm._v(
              " " +
                _vm._s(_vm.$t("tenant.page.dashboard.data.headers.customer")) +
                " "
            )
          ]
        ),
        _c(
          "th",
          {
            staticClass:
              "px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase",
            attrs: { scope: "col" }
          },
          [
            _vm._v(
              " " +
                _vm._s(_vm.$t("tenant.page.dashboard.data.headers.product")) +
                " "
            )
          ]
        )
      ])
    ]),
    _c(
      "tbody",
      {
        staticClass: "bg-white divide-y divide-gray-200",
        attrs: { "x-max": "1" }
      },
      _vm._l(_vm.getDashboardData["undownloaded_sessions"], function(item) {
        return _c("tr", { key: item.id }, [
          _c("td", { staticClass: "px-6 py-4 whitespace-nowrap" }, [
            _c("div", { staticClass: "flex items-center" }, [
              _c("div", {}, [
                _c(
                  "div",
                  { staticClass: "text-sm font-medium text-gray-900" },
                  [_vm._v(" " + _vm._s(item.customer_name) + " ")]
                )
              ])
            ])
          ]),
          _c(
            "td",
            {
              staticClass: "px-6 py-4 text-sm text-gray-500 whitespace-nowrap"
            },
            [_vm._v(" " + _vm._s(item.product_name) + " ")]
          )
        ])
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }