<template>
  <div class="ma-4" app>
    <v-container fluid class="full-height">
      <v-row>
        <v-col md="12">
          <ProductsTable />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
// @ is an alias to /src
import ProductsTable from "./Components/ProductsTable.vue";
export default {
  name: "Home",
  components: {
    ProductsTable
  },
  mounted() {
    this.$store.dispatch("getProducts");
  }
};
</script>
