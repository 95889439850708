import axios from "axios";

export default {
  state: {
    sessions: [],
    loadingSessions: false
  },
  actions: {
    getSessions({ commit, state, rootState }) {
      console.log("Fetching Sessions");
      commit("setLoadingSessions", true);
      const user = rootState.currentUser;
      var url = `${process.env.VUE_APP_API_URL}/get_sessions`;
      user
        .getIdToken(true)
        .then(async function (token) {
          const headers = {
            Authorization: token.toString(),
            "Content-Type": "application/json"
          };
          var hosturl = window.location.host;
          var tenant = hosturl.split(".")[0];
          url = url + "?tenant=" + tenant;
          const response = await axios.get(url, {
            headers: headers
          });
          commit("setSessions", response.data);
          console.log(state.sessions);
        })
        .catch(error => {
          console.log(error);
          commit("setSessions", []);
        });
    }
  },
  mutations: {
    setSessions(state, payload) {
      state.sessions = payload;
      state.loadingSessions = false;
    },
    setLoadingSessions(state, payload) {
      state.loadingSessions = payload;
    }
  },
  getters: {
    getLoadingSessions: state => state.loadingSessions,
    getSessions: state => state.sessions
  }
};
