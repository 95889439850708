import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "../../plugins/vuetify";
import i18n from '../../plugins/i18n';

let insurer_app;
insurer_app = new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");

export default insurer_app;
