<template>
  <v-container>
    <v-row>
      <v-col sm="12">
        <div class="sm:overflow-hidden">
          <div class="flex justify-between px-4">
            <h3 class="text-lg font-medium text-gray-900">
              {{ $t("tenant.page.products.details") }}
            </h3>

            <div v-if="!isViewer">
              <v-btn
                color="blue-grey lighten-2"
                outlined
                @click="closeEdit()"
                v-if="getShowEditForm"
              >
                {{ $t("tenant.page.products.cancel") }}
              </v-btn>
              <v-btn
                color="blue-grey lighten-2"
                outlined
                @click="showEdit()"
                v-else
              >
                {{ $t("tenant.page.products.edit") }}
              </v-btn>
            </div>
          </div>

          <product-edit
            :product="this.getProductDetails"
            v-if="getShowEditForm"
          />
          <product-view :product="this.getProductDetails" v-else />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/*eslint no-debugger: "warn"*/
import View from "./Components/View";
import Edit from "./Components/Edit";
import { mapGetters } from "vuex";
export default {
  components: {
    "product-view": View,
    "product-edit": Edit,
  },
  mounted() {
    this.$store.dispatch("getProductDetails");
  },
  beforeDestroy() {
    this.closeEdit();
  },
  computed: {
    ...mapGetters([
      "getProductDetails",
      "getLoadingProductData",
      "getShowEditForm",
      "isViewer",
    ]),
  },
  methods: {
    showEdit() {
      this.$store.commit("setShowEditForm", true);
    },
    closeEdit() {
      this.$store.commit("setShowEditForm", false);
    },
  },
};
</script>

<style></style>
