<template>
  <v-row justify="end">
    <v-dialog v-model="dialog" max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" dark v-bind="attrs" v-on="on">
          {{ $t("tenant.page.customers.new.title") }}
        </v-btn>
      </template>

      <v-card>
        <v-card-title>{{ $t("tenant.page.customers.new.title") }}</v-card-title>
        <v-card-text>
          <v-form ref="form" lazy-validation>
            <section>
              <h3 class="mb-4 text-xl text-gray-800">
                {{ $t("tenant.page.customers.new.customer") }}
              </h3>
              <v-text-field
                :label="$t('tenant.page.customers.new.name')"
                v-model="customer_name"
                :rules="nameRules"
                required
                outlined
              ></v-text-field>

              <v-text-field
                :label="$t('tenant.page.customers.new.identifier')"
                :hint="$t('tenant.page.customers.new.identifierhint')"
                v-model="identifier"
                outlined
                :persistent-hint="true"
              ></v-text-field>
            </section>

            <section>
              <h3 class="mb-4 text-xl text-gray-800">
                {{ $t("tenant.page.customers.new.admin") }}
              </h3>

              <v-text-field
                :label="$t('tenant.page.customers.new.name')"
                v-model="customer_admin_name"
                :rules="nameRules"
                required
                outlined
              ></v-text-field>

              <v-text-field
                :label="$t('tenant.page.customers.new.email')"
                required
                outlined
                v-model="email"
                :rules="emailRules"
              ></v-text-field>

              <v-text-field
                type="password"
                :label="$t('tenant.page.customers.new.password')"
                required
                outlined
                v-model="password"
                :rules="passwordRules"
              ></v-text-field>
            </section>

            <div class="flex justify-end space-x-3">
              <v-btn
                color="grey darken-1"
                text
                small
                @click.stop.prevent="closeDialog"
              >
                {{ $t("tenant.page.customers.new.cancel") }}
              </v-btn>
              <v-btn
                color="blue darken-1"
                outlined
                small
                @click.stop.prevent="createUser"
              >
                {{ $t("tenant.page.customers.new.title") }}
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data: () => ({
    dialog: false,
    subscriptions: [{}],
    customer_name: "",
    customer_admin_name: "",
    email: "",
    password: "",
    identifier: "",
  }),
  methods: {
    addSubscription() {
      this.subscriptions.push({});
    },
    nextTab() {
      this.tabs = 1;
    },
    closeDialog() {
      this.dialog = false;
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },
    createUser() {
      const isFormValid = this.$refs.form.validate();
      if (isFormValid) {
        var data = {
          customer_name: this.customer_name,
          customer_admin_name: this.customer_admin_name,
          email: this.email,
          password: this.password,
          identifier: this.identifier,
        };
        this.$store.dispatch("createDistributor", data);
        this.closeDialog();
      }
    },
  },
  computed: {
    ...mapGetters(["getProducts"]),
    nameRules() {
      return [
        (v) =>
          !!v ||
          this.$t("validation.required", { value: this.$t("common.name") }),
        (v) =>
          v.length >= 3 ||
          this.$t("validation.string.length>=", {
            value: this.$t("common.name"),
            length: "3",
          }),
      ];
    },
    passwordRules() {
      return [
        (v) =>
          !!v ||
          this.$t("validation.required", {
            value: this.$t("common.password"),
          }),
        (v) =>
          (v && v.length >= 6) ||
          this.$t("validation.string.length>=", {
            value: this.$t("common.password"),
            length: "6",
          }),
      ];
    },
    emailRules() {
      return [
        (v) =>
          !!v ||
          this.$t("validation.required", { value: this.$t("common.email") }),
        (v) => /.+@.+\..+/.test(v) || this.$t("validation.email.valid"),
      ];
    },
  },
};
</script>

<style></style>
