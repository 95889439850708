<template>
  <div>
    <div class="shadow sm:rounded-md sm:overflow-hidden">
      <div class="flex px-4 py-5 bg-white border-b border-gray-200 sm:p-6">
        <h3 class="text-lg font-medium text-gray-900">
          {{ $t("tenant.page.customers.title") }}
        </h3>
        <v-spacer></v-spacer>
        <UserDialog class="mr-2" v-if="!isViewer" />
      </div>
      <div class="pb-5 space-y-6 bg-white">
        <v-data-table
          :loading="getLoadingDistributors"
          :loading-text="$t('tenant.page.customers.progress')"
          :headers="headers"
          :items="getDistributors"
          :items-per-page="30"
          flat
        >
          <template v-slot:item.number_of_subscriptions="{ item }">
            {{ $n(item.number_of_subscriptions) }}
          </template>
          <template v-slot:item.action="{ item }">
            <v-btn
              text
              small
              class="buttonMod"
              color="primary"
              @click="showSubs(item)"
            >
              {{ $t("tenant.page.customers.headers.viewsubscriptions") }}
            </v-btn>
          </template>
        </v-data-table>
      </div>
    </div>
    <v-dialog v-model="dialog" max-width="700">
      <v-card>
        <v-card-title class="headline">
          <v-row>
            <v-col cols="7"
              ><h4>
                {{ $t("tenant.page.customers.subscriptions.title") }}
              </h4></v-col
            >
            <v-spacer></v-spacer>
            <v-col cols="5">
              <v-btn color="primary" @click="showAddDiag" v-if="!isViewer">
                {{ $t("tenant.page.customers.subscriptions.add") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-list dense>
            <v-subheader>
              {{ $t("tenant.page.customers.subscriptions.products") }}
            </v-subheader>

            <v-list-item
              v-for="(item, i) in selectedCustomer.subscriptions"
              :key="i"
            >
              <v-list-item-content>
                <v-list-item-title>{{ item.product_name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="subDialog" max-width="320">
      <v-card>
        <v-card-text>
          <v-row>
            <v-col>
              <span>{{
                $t("tenant.page.customers.subscriptions.addproduct")
              }}</span>
            </v-col>
          </v-row>
          <v-select
            :items="getProducts"
            item-text="name"
            v-on:change="newSubChange"
          >
          </v-select>
          <small>{{ $t("tenant.page.customers.subscriptions.hint") }}</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            outlined
            @click="addSubscriptionCloseDialog"
          >
            {{ $t("tenant.page.customers.subscriptions.add") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UserDialog from "./NewDistributorDialog";
export default {
  components: {
    UserDialog,
  },
  data() {
    return {
      dialog: false,
      selectedCustomer: {},
      subDialog: false,
    };
  },
  methods: {
    showSubs(customer) {
      this.dialog = true;
      this.selectedCustomer = customer;
    },
    showAddDiag() {
      this.subDialog = true;
    },
    addSubscriptionCloseDialog() {
      let data = {};
      data.bmm_dex_product_id = this.product.id;
      data.bmm_dex_customer_id = this.selectedCustomer.id;
      console.log(data);
      this.$store.dispatch("createDistributorSubscription", data);
      this.subDialog = false;
      this.dialog = false;
    },
    newSubChange(event) {
      let index = this.getProducts.findIndex((x) => x.name === event);
      this.product = this.getProducts[index];
    },
  },
  computed: {
    ...mapGetters([
      "getDistributors",
      "getLoadingDistributors",
      "getProducts",
      "isViewer",
    ]),
    headers() {
      return [
        { text: this.$t("tenant.page.customers.headers.name"), value: "name" },
        {
          text: this.$t("tenant.page.customers.new.identifier"),
          value: "identifier",
        },
        {
          text: this.$t("tenant.page.customers.headers.adminemail"),
          value: "admin_email",
        },
        {
          text: this.$t("tenant.page.customers.headers.subscriptions"),
          value: "number_of_subscriptions",
        },
        {
          text: this.$t("tenant.page.customers.headers.viewsubscriptions"),
          value: "action",
          sortable: false,
        },
      ];
    },
  },
};
</script>

<style>
.buttonMod {
  text-transform: none;
}
</style>
