var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        { attrs: { tile: "" } },
        [
          _c(
            "v-card-title",
            [
              _c(
                "v-row",
                { staticClass: "mx-4" },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("tenant.page.subscriptionsessions.title")) +
                      " "
                  ),
                  _c("v-spacer")
                ],
                1
              )
            ],
            1
          ),
          _c("v-data-table", {
            attrs: {
              loading: _vm.getLoadingSubSessions,
              "loading-text": _vm.$t(
                "tenant.page.subscriptionsessions.progress"
              ),
              headers: _vm.headers,
              items: _vm.getSubSessions,
              "items-per-page": 30,
              flat: ""
            },
            scopedSlots: _vm._u([
              {
                key: "item.total_document_count",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _vm._v(
                      " " + _vm._s(_vm.$n(item.total_document_count)) + " "
                    )
                  ]
                }
              },
              {
                key: "item.failed_validation_document_count",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$n(item.failed_validation_document_count)) +
                        " "
                    )
                  ]
                }
              },
              {
                key: "item.reads",
                fn: function(ref) {
                  var item = ref.item
                  return [_vm._v(" " + _vm._s(_vm.$n(item.reads)) + " ")]
                }
              },
              {
                key: "item.session_id",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c(
                      "v-btn",
                      {
                        staticClass: "buttonMod",
                        attrs: { text: "", small: "", color: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.setCurrentSession(item)
                          }
                        }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "tenant.page.subscriptionsessions.headers.data"
                              )
                            ) +
                            " "
                        )
                      ]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }