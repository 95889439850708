import axios from "axios";

export default {
  state: {
    loadingSessionData: true,
    sessions: [],
    headers: [],
    pagination: {}
  },
  actions: {
    // async getSessionData({ commit, rootState, state }, payload) {
    async getSessionData({ commit, rootState }, payload) {
      console.log("Fetching Session Data");
      commit("setLoadingSessionData", true);
      const user = rootState.currentUser;
      const tenantId = rootState.tenantId;
      var url = `${process.env.VUE_APP_API_URL}/get_session_events`;
      user
        .getIdToken(true)
        .then(async function (token) {
          const headers = {
            Authorization: token.toString(),
            "Content-Type": "application/json"
          };
          var hosturl = window.location.host;
          var subdomain = hosturl.split(".")[0];
          // url = url + "?tenant=" + tenant;
          url = url + "?subdomain=" + subdomain;
          url = url + "&tenant_id=" + tenantId;
          // url = url + "&ref_id=" + payload.ref_id;
          url = url + "&bmm_dex_session_id=" + payload.ref_id;
          if (payload.before) {
            url = url + "&before=" + payload.before;
          }
          if (payload.after) {
            url = url + "&after=" + payload.after;
          }
          // url = url + "&event=" + payload.event;
          console.log(`DEBUG: Sessions/Store/getSessionData-get_session_events -- url => ${url}`);
          const response = await axios.get(url, {
            headers: headers
          });
          // console.log(response.data);
          commit("setPagination", response.data.pagination);
          commit("setHeaders", response.data.headers);
          commit("setSessionData", response.data.all);
          // console.log(state.sessions);
        })
        .catch(error => {
          console.log(error);
          commit("setSessionData", []);
        });
    }
  },
  mutations: {
    setSessionData(state, payload) {
      state.sessions = payload;
      state.loadingSessionData = false;
    },
    setLoadingSessionData(state, payload) {
      state.loadingSessionData = payload;
    },
    setHeaders(state, payload) {
      state.headers = payload;
    },
    setPagination(state, payload) {
      state.pagination = payload
    }
  },
  getters: {
    getLoadingSessionData: state => state.loadingSessionData,
    getSessionData: state => state.sessions,
    getHeaders: state => state.headers,
    getPagination: state => state.pagination
  }
};
