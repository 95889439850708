var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nav",
    [
      _c(
        "v-app-bar",
        { staticClass: "grey lighten-3", attrs: { flat: "", app: "" } },
        [
          _vm.getCurrentUser != null
            ? _c("v-app-bar-nav-icon", {
                staticClass: "grey--text",
                on: { click: _vm.toggleDrawer }
              })
            : _vm._e(),
          _c("v-toolbar-title", { staticClass: "text-uppercase grey--text" }, [
            _c("span", [
              _c("img", {
                staticClass: "w-auto h-8 mr-3 sm:h-10",
                attrs: {
                  src: require("../../../assets/images/logo-17.png"),
                  alt: "dex-logo"
                }
              })
            ])
          ]),
          _c("v-spacer"),
          _c(
            "div",
            { staticClass: "flex justify-end space-x-6" },
            [
              _c("LocaleSwitcher"),
              _vm.getCurrentUser != null
                ? _c(
                    "v-btn",
                    {
                      attrs: { color: "secondary", outlined: "" },
                      on: { click: _vm.signOut }
                    },
                    [
                      _c("span", [_vm._v(_vm._s(_vm.$t("common.signout")))]),
                      _c("v-icon", { attrs: { right: "" } }, [
                        _vm._v("exit_to_app")
                      ])
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-navigation-drawer",
        {
          staticClass: "primary",
          attrs: { value: _vm.getDrawerStatus, app: "", dark: "" },
          scopedSlots: _vm._u([
            {
              key: "append",
              fn: function() {
                return [
                  _c(
                    "v-list",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-list-item",
                        [
                          _c(
                            "v-list-item-icon",
                            [_c("v-icon", [_vm._v("account_circle")])],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", {
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.getCurrentUser.displayName
                                  )
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        [
                          _c(
                            "v-list-item-icon",
                            [_c("v-icon", [_vm._v("email")])],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", {
                                domProps: {
                                  textContent: _vm._s(_vm.getCurrentUser.email)
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        [
                          _c(
                            "v-list-item-icon",
                            [_c("v-icon", [_vm._v("apartment")])],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", [
                                _vm._v(
                                  _vm._s(
                                    _vm.getTenantDetails
                                      ? _vm.getTenantDetails.tenant_name
                                      : "Unknown"
                                  )
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c(
            "v-list",
            { staticClass: "pt-16" },
            _vm._l(_vm.links, function(link, i) {
              return _c(
                "div",
                { key: i },
                [
                  !link.sublinks
                    ? _c(
                        "v-list-item",
                        { key: i, attrs: { router: "", to: link.route } },
                        [
                          _c(
                            "v-list-item-icon",
                            [
                              _c("v-icon", {
                                domProps: { textContent: _vm._s(link.icon) }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", {
                                domProps: { textContent: _vm._s(link.text) }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _c(
                        "v-list-group",
                        {
                          attrs: {
                            value: false,
                            "prepend-icon": link.icon,
                            color: "white"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function() {
                                  return [
                                    _c(
                                      "v-list-item-content",
                                      [
                                        _c("v-list-item-title", [
                                          _vm._v(_vm._s(link.text))
                                        ])
                                      ],
                                      1
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            true
                          )
                        },
                        _vm._l(link.sublinks, function(sublink, i) {
                          return _c(
                            "v-list-item",
                            { key: i, attrs: { to: sublink.route } },
                            [
                              _c(
                                "div",
                                { staticClass: "flex pl-4" },
                                [
                                  _c(
                                    "v-list-item-icon",
                                    [
                                      _c("v-icon", [
                                        _vm._v(_vm._s(sublink.icon))
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-title", {
                                        domProps: {
                                          textContent: _vm._s(sublink.text)
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        }),
                        1
                      )
                ],
                1
              )
            }),
            0
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }