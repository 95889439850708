<template>
  <div>
    <v-card tile>
      <v-card-title>
        <v-row class="mx-4">
          {{ $t("tenant.page.subscriptionsessions.title") }}
          <v-spacer></v-spacer>
        </v-row>
      </v-card-title>
      <v-data-table
        :loading="getLoadingSubSessions"
        :loading-text="$t('tenant.page.subscriptionsessions.progress')"
        :headers="headers"
        :items="getSubSessions"
        :items-per-page="30"
        flat
      >
        <template v-slot:item.total_document_count="{ item }">
          {{ $n(item.total_document_count) }}
        </template>
        <template v-slot:item.failed_validation_document_count="{ item }">
          {{ $n(item.failed_validation_document_count) }}
        </template>
        <template v-slot:item.reads="{ item }">
          {{ $n(item.reads) }}
        </template>
        <template v-slot:item.session_id="{ item }">
          <v-btn
            text
            small
            color="primary"
            class="buttonMod"
            v-on:click="setCurrentSession(item)"
          >
            {{ $t("tenant.page.subscriptionsessions.headers.data") }}
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    ...mapGetters(["getSubSessions", "getLoadingSubSessions"]),
    headers() {
      return [
        {
          text: this.$t("tenant.page.subscriptionsessions.headers.product"),
          value: "product_name",
        },
        {
          text: this.$t("tenant.page.subscriptionsessions.headers.event"),
          value: "bmm_dex_event_type",
        },
        {
          text: this.$t("tenant.page.subscriptionsessions.headers.customer"),
          value: "customer_name",
        },
        {
          text: this.$t("tenant.page.subscriptionsessions.headers.row"),
          value: "total_document_count",
        },
        {
          text: this.$t("tenant.page.subscriptionsessions.headers.rowerrors"),
          value: "failed_validation_document_count",
          sortable: true,
        },
        {
          text: this.$t("tenant.page.subscriptionsessions.headers.reads"),
          value: "reads",
        },
        {
          text: this.$t("tenant.page.subscriptionsessions.headers.downloaded"),
          value: "downloaded",
        },
        {
          text: this.$t("tenant.page.subscriptionsessions.headers.data"),
          value: "session_id",
          sortable: false,
        },
      ];
    },
  },
  methods: {
    setCurrentSession(session) {
      this.$store.commit("setCurrentSession", session);
      this.$router.push({
        name: "Session",
        params: { id: `${session.session_id}` },
      });
    },
  },
};
</script>

<style>
.buttonMod {
  text-transform: none;
}
</style>
