import axios from "axios";
import firebase from "firebase/app";

export default {
  state: {
    products: [],
    productSubs: [],
    loadingProducts: false,
    productSubsLoading: false,
    dialog: false,
    tenantId: ""
  },
  actions: {
    getProducts({ commit, rootState }) {

      commit("setLoadingProducts", true);
      console.log("get Products");
      let user = firebase.auth().currentUser;
      let url = `${process.env.VUE_APP_API_URL}/get_products`;
      user
        .getIdToken(true)
        .then(async function (token) {
          const headers = {
            Authorization: token.toString(),
            "Content-Type": "application/json"
          };

          const tenantId = rootState.tenantId;
          // console.log(`DEBUG: tenant/products/store ..tenant_id => ${tenantId}`)
          // var hosturl = window.location.host;
          // var tenant = hosturl.split(".")[0];
          url = url + "?tenant_id=" + tenantId;

          console.log(`DEBUG: tenant/products/store ..URL => ${url}`)

          const response = await axios.get(url, {
            headers: headers
          });

          // console.log(`DEBUG: AFTER AXIOS..tenant/products/store ..URL => ${url}`)
          console.log(response.data);
          commit("setProducts", response.data);
        })
        .catch(error => {
          console.log(error);
          commit("setProducts", []);
        });
    },
    // getProductSubs({ commit, state, rootState }, payload) {
    getProductSubs({ commit, rootState }, payload) {
      commit("setProductSubscriptions", []);
      commit("setLoadingProductSubs", true);
      const user = firebase.auth().currentUser;
      var url = `${process.env.VUE_APP_API_URL}/get_product_subscriptions`;
      user
        .getIdToken(true)
        .then(async function (token) {
          const headers = {
            Authorization: token.toString(),
            "Content-Type": "application/json"
          };
          var hosturl = window.location.host;
          var tenant = hosturl.split(".")[0];
          url = url + "?tenant=" + tenant;
          url = url + "&tenant_id=" + rootState.tenantId;
          url = url + "&product_id=" + payload;
          console.log(url);
          const response = await axios.get(url, {
            headers: headers
          });
          console.log("DEBUG: get_product_subscriptions => " + response.data);
          commit("setProductSubscriptions", response.data);
          // console.log("DEBUG: state.sessions => " + state.sessions);
        })
        .catch(error => {
          console.log(error);
          commit("setProductSubscriptions", []);
        });
    },
    async saveProduct({ commit, dispatch, rootState, rootGetters }, payload) {
      if (!rootGetters.isViewer) {
        console.log(payload);
        commit("setLoadingProductSubs", true);
        commit("setDialog", false);
        var hosturl = window.location.host;
        var tenant = hosturl.split(".")[0];
        let tenantId = rootState.tenantId;
        const url = `${process.env.VUE_APP_API_URL}/save_product_config`;
        var data = {};
        data.tenant = tenant;
        data.tenant_id = tenantId;
        data.data = payload;
        const user = rootState.currentUser;
        user
          .getIdToken(true)
          .then(async function (token) {
            const headers = {
              Authorization: token.toString(),
              "Content-Type": "application/json",
              Accept: "application/json"
            };
            const response = await axios.post(url, data, {
              headers: headers
            });
            if (response.status == "200") {
              console.clear();
              console.log("Posted Product Successfully");
              dispatch("getProducts");
            } else {
              console.log(response.status);
              console.log(response.json);
              console.log(response);
            }
          })
          .catch(error => {
            console.log(error);
          });
      }
    }
  },
  mutations: {
    setProducts(state, payload) {
      state.products = payload;
      state.loadingProducts = false;
    },
    setLoadingProducts(state, payload) {
      state.loadingProducts = payload;
    },
    setProductSubscriptions(state, payload) {
      state.productSubs = payload;
      state.productSubsLoading = false;
    },
    setLoadingProductSubs(state, payload) {
      state.productSubsLoading = payload;
    },
    setDialog(state, payload) {
      state.dialog = payload;
    }
  },
  getters: {
    getProducts: state => state.products,
    getLoadingProducts: state => state.loadingProducts,
    getProductSubsLoading: state => state.productSubsLoading,
    getProductSubs: state => state.productSubs,
    getDialog: state => state.dialog
  }
};
