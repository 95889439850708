import axios from "axios";

export default {
  state: {
    loadingSubscriptionSessions: false,
    subscriptionSessions: [],
    currentSessionCollection: "",
    currentSession: {}
  },
  actions: {
    async getSubscriptionSessions({ commit, rootState }, payload) {
      commit("setSubscriptionSessions", []);
      commit("setLoadingSubscriptionSessions", true);
      const user = rootState.currentUser;
      var url = `${process.env.VUE_APP_API_URL}/get_subscription_sessions`;
      user
        .getIdToken(true)
        .then(async function (token) {
          const headers = {
            Authorization: token.toString(),
            "Content-Type": "application/json"
          };
          var hosturl = window.location.host;
          var tenant = hosturl.split(".")[0];
          url = url + "?tenant=" + tenant;
          url = url + "&tenantId=" + rootState.tenantId;
          url = url + "&subscription_id=" + payload;
          url = url + "&bmm_dex_subscription_id=" + payload;
          console.log("DEBUG: SubscriptionSubmissions/Store url => " + url);
          const response = await axios.get(url, {
            headers: headers
          });
          console.log("DEBUG: SubscriptionSubmissions/Store get_subscription_sessions => " + response.data);
          commit("setSubscriptionSessions", response.data);
        })
        .catch(error => {
          console.log(error);
          commit("setSubscriptionSessions", []);
        });
    }
  },
  mutations: {
    setSubscriptionSessions(state, payload) {
      state.subscriptionSessions = payload;
      state.loadingSubscriptionSessions = false;
    },
    setLoadingSubscriptionSessions(state, payload) {
      state.loadingSubscriptionSessions = payload;
    },
    setCurrentSessionCollection(state, payload) {
      state.currentSessionCollection = payload;
    },
    setCurrentSession(state, payload) {
      var currentSession = [];
      currentSession.push(payload);
      state.currentSession = currentSession;
    }
  },
  getters: {
    getSubSessions: state => state.subscriptionSessions,
    getLoadingSubSessions: state => state.loadingSubscriptionSessions,
    getCurrentSessionCollection: state => state.currentSessionCollection,
    getCurrentSession: state => state.currentSession
  }
};
