<template>
  <v-app>
    <Navbar />
    <v-main class="grey lighten-4">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import Navbar from "./components/Navbar.vue";
export default {
  components: { Navbar },
  data: () => ({}),
  created() {
    this.$store.dispatch("authAction");
  },
  mounted() {
    // this.$store.dispatch("authAction");
    const path = "/"
    if (this.$route.path !== path) this.$router.push(path)
  }

};
</script>

<style></style>
