<template>
  <div class="p-5 space-y-6">
    <h2 class="text-lg font-bold">
      {{ product.name }}
    </h2>

    <div>
      <h3 class="mb-4 text-base font-medium">
        {{ $t("tenant.page.products.new.event.title") }}
      </h3>
      <v-expansion-panels>
        <v-expansion-panel
          v-for="(eventdetails, event, i) in this.product.events"
          :key="i"
        >
          <v-expansion-panel-header class="grey lighten-5">
            <h4 class="text-base font-normal">{{ event }}</h4>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <h4 class="mt-4 text-sm font-bold">
              {{ $t("tenant.page.products.new.fields.title") }}
            </h4>

            <v-expansion-panels class="mt-4 divide-y-2" accordion flat>
              <v-expansion-panel
                v-for="(fieldDetails, k) in visibleFields(eventdetails)"
                :key="k"
              >
                <v-expansion-panel-header>
                  {{ fieldDetails.name }}
                </v-expansion-panel-header>

                <v-expansion-panel-content>
                  <v-row>
                    <v-col cols="3">
                      <span
                        >{{ $t("tenant.page.products.new.fields.required") }}:
                        {{ fieldDetails.nullable ? "False" : "True" }}</span
                      >
                    </v-col>

                    <v-col cols="9">
                      <div class="mb-3" v-if="fieldDetails.text_file_config">
                        <h5>
                          {{ $t("tenant.page.products.new.fields.structured") }}
                        </h5>
                        <v-simple-table>
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th>
                                  {{
                                    $t("tenant.page.products.new.fields.length")
                                  }}
                                </th>
                                <th>
                                  {{
                                    $t(
                                      "tenant.page.products.new.fields.sequencenumber"
                                    )
                                  }}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  {{ fieldDetails.text_file_config.length }}
                                </td>
                                <td>
                                  {{
                                    fieldDetails.text_file_config
                                      .sequence_number
                                  }}
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </div>

                      <div
                        v-if="
                          fieldDetails.validations &&
                          fieldDetails.validations.length != 0
                        "
                      >
                        <h5>
                          {{ $t("tenant.page.products.new.validations.title") }}
                        </h5>
                        <v-data-table
                          hide-default-footer
                          :headers="validationHeaders"
                          :items="fieldDetails.validations"
                        >
                        </v-data-table>
                      </div>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </div>
</template>

<script>
export default {
  props: { product: Object },
  computed: {
    validationHeaders() {
      return [
        {
          text: this.$t("tenant.page.products.new.validations.headers.name"),
          value: "name",
        },
        {
          text: this.$t("tenant.page.products.new.validations.headers.type"),
          value: "validation_type",
        },
      ];
    },
  },
  methods: {
    visibleFields(event) {
      return event.data_fields.filter(
        (field) => !field.name.startsWith("bmm_dex")
      );
    },
  },
};
</script>