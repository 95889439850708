var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "flex px-1 py-5" }, [
    _c("div", { staticClass: "w-full md:flex" }, [
      _c("div", { staticClass: "w-full px-2 md:w-1/3" }, [
        _c("div", { staticClass: "mb-4 rounded-lg shadow-sm" }, [
          _c(
            "div",
            {
              staticClass: "relative overflow-hidden bg-white rounded-lg shadow"
            },
            [
              _c(
                "div",
                { staticClass: "relative z-10 px-3 pt-8 pb-10 text-center" },
                [
                  _c(
                    "h4",
                    {
                      staticClass:
                        "text-sm leading-tight text-gray-500 uppercase"
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("tenant.page.dashboard.summary.sessions")
                          ) +
                          " "
                      )
                    ]
                  ),
                  _c(
                    "h3",
                    {
                      staticClass:
                        "my-3 text-3xl font-semibold leading-tight text-gray-700"
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$n(_vm.getDashboardData["session_count"])
                          ) +
                          " "
                      )
                    ]
                  )
                ]
              ),
              _vm._m(0)
            ]
          )
        ])
      ]),
      _c("div", { staticClass: "w-full px-2 md:w-1/3" }, [
        _c("div", { staticClass: "mb-4 rounded-lg shadow-sm" }, [
          _c(
            "div",
            {
              staticClass: "relative overflow-hidden bg-white rounded-lg shadow"
            },
            [
              _c(
                "div",
                { staticClass: "relative z-10 px-3 pt-8 pb-10 text-center" },
                [
                  _c(
                    "h4",
                    {
                      staticClass:
                        "text-sm leading-tight text-gray-500 uppercase"
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "tenant.page.dashboard.summary.subscriptions"
                            )
                          ) +
                          " "
                      )
                    ]
                  ),
                  _c(
                    "h3",
                    {
                      staticClass:
                        "my-3 text-3xl font-semibold leading-tight text-gray-700"
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$n(_vm.getDashboardData["subscription_count"])
                          ) +
                          " "
                      )
                    ]
                  )
                ]
              ),
              _vm._m(1)
            ]
          )
        ])
      ]),
      _c("div", { staticClass: "w-full px-2 md:w-1/3" }, [
        _c("div", { staticClass: "mb-4 rounded-lg shadow-sm" }, [
          _c(
            "div",
            {
              staticClass: "relative overflow-hidden bg-white rounded-lg shadow"
            },
            [
              _c(
                "div",
                { staticClass: "relative z-10 px-3 pt-8 pb-10 text-center" },
                [
                  _c(
                    "h4",
                    {
                      staticClass:
                        "text-sm leading-tight text-gray-500 uppercase"
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("tenant.page.dashboard.summary.customers")
                          ) +
                          " "
                      )
                    ]
                  ),
                  _c(
                    "h3",
                    {
                      staticClass:
                        "my-3 text-3xl font-semibold leading-tight text-gray-700"
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$n(_vm.getDashboardData["customer_count"])
                          ) +
                          " "
                      )
                    ]
                  )
                ]
              ),
              _vm._m(2)
            ]
          )
        ])
      ]),
      _c("div", { staticClass: "w-full px-2 md:w-1/3" }, [
        _c("div", { staticClass: "mb-4 rounded-lg shadow-sm" }, [
          _c(
            "div",
            {
              staticClass: "relative overflow-hidden bg-white rounded-lg shadow"
            },
            [
              _c(
                "div",
                { staticClass: "relative z-10 px-3 pt-8 pb-10 text-center" },
                [
                  _c(
                    "h4",
                    {
                      staticClass:
                        "text-sm leading-tight text-gray-500 uppercase"
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("tenant.page.dashboard.summary.products")
                          ) +
                          " "
                      )
                    ]
                  ),
                  _c(
                    "h3",
                    {
                      staticClass:
                        "my-3 text-3xl font-semibold leading-tight text-gray-700"
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$n(_vm.getDashboardData["product_count"])
                          ) +
                          " "
                      )
                    ]
                  )
                ]
              ),
              _vm._m(3)
            ]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "absolute inset-x-0 bottom-0" }, [
      _c("canvas", { attrs: { id: "chart1", height: "70" } })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "absolute inset-x-0 bottom-0" }, [
      _c("canvas", { attrs: { id: "chart2", height: "70" } })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "absolute inset-x-0 bottom-0" }, [
      _c("canvas", { attrs: { id: "chart3", height: "70" } })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "absolute inset-x-0 bottom-0" }, [
      _c("canvas", { attrs: { id: "chart3", height: "70" } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }