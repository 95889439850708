<template>
  <v-dialog
    v-model="getDialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ attrs }">
      <v-btn color="primary" dark v-bind="attrs" @click="showDialog">
        {{ $t("tenant.page.products.new.title") }}
      </v-btn>
    </template>
    <v-card class="grey lighten-4">
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{
          $t("tenant.page.products.new.subtitle")
        }}</v-toolbar-title>
      </v-toolbar>
      <ProductForm></ProductForm>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters } from "vuex";
import ProductForm from "./NewProductForm";
export default {
  data: () => ({
    dialog: false,
  }),
  methods: {
    showDialog() {
      this.$store.commit("setDialog", true);
    },
    closeDialog() {
      this.$store.commit("setDialog", false);
    },
    onSelectValidation(event) {
      console.log("Selected event");
      console.log(event);
      var sel_val = this.validations.findIndex((x) => x.name == event);
      console.log(sel_val);
      this.selected_validation = this.validations[sel_val];
      console.log(this.selected_validation.has_attribute);
    },
    nextTab() {
      this.tabs = 1;
    },
  },
  components: { ProductForm },
  computed: { ...mapGetters(["getDialog"]) },
};
</script>

<style>
.fields {
  border: 0.5px solid;
  padding: 5px;
  margin-bottom: 10px;
  border-color: #d3d3d3;
}
</style>
