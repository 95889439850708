import firebase from "firebase/app";
import router from "../../../router";
import axios from "axios";
var db = firebase.firestore();

export default {
  state: {
    product_details: {},
    product_details_loading: false,
    show_edit_form: false
  },
  actions: {
    // async getProductDetails({ commit, rootState }) {
    async getProductDetails({ commit }) {
      commit("setProductDetails", {});
      console.clear();
      console.log("Fetching the Product details");
      var product_id = router.currentRoute.params.id;
      var hosturl = window.location.host;
      var tenant = hosturl.split(".")[0];

      // const tenant_id = rootState.tenant_id

      // console.log("DEBUG: INSIDE ProductDetails/Store -- getProductDetails(). Tenant ID => " + tenant_id);

      // var insurer_doc = db.collection("Insurers").doc(tenant.toUpperCase());
      // var insurer_doc = db.collection("tenants").doc(tenant_id);

      var matching_tenant_docs = await db.collection("tenants").where("subdomain", "==", tenant).get();
      // console.log(matching_tenant_docs);

      var matching_docs_ids = [];

      matching_tenant_docs.forEach(doc => {
        matching_docs_ids.push(doc.id);
      })
      const tenant_doc_id = matching_docs_ids[0];

      console.log("DEBUG: tenant_doc_id => " + tenant_doc_id);
      var insurer_doc = db.collection("tenants").doc(tenant_doc_id);
      var product_ref = insurer_doc.collection("products").doc(product_id);
      var product = await product_ref.get();
      var product_data = product.data();
      console.log("The Data");
      console.log(product_data);
      commit("setProductDetails", product_data);
    },
    async updateProduct({ commit, dispatch, rootState, rootGetters }, payload) {
      if (!rootGetters.isViewer) {
        console.log(payload);
        const hosturl = window.location.host;
        const tenant = hosturl.split(".")[0];
        const tenantId = rootState.tenantId;
        const product_id = router.currentRoute.params.id;
        const url = `${process.env.VUE_APP_API_URL}/save_product_config`;
        const data = {};
        data.tenant = tenant;
        data.tenant_id = tenantId;
        data.product_id = product_id;
        data.data = payload;
        const user = firebase.auth().currentUser;
        user
          .getIdToken(true)
          .then(async function (token) {
            const headers = {
              Authorization: token.toString(),
              "Content-Type": "application/json",
              Accept: "application/json"
            };
            const response = await axios.put(url, data, {
              headers: headers
            });
            if (response.status == "200") {
              console.clear();
              console.log("Update Product Successfully");
              dispatch("getProductDetails");
              commit("setShowEditForm", false);
            } else {
              console.log(response.status);
              console.log(response.json);
              console.log(response);
            }
          })
          .catch(error => {
            console.log(error);
          });
      }
    }
  },
  mutations: {
    setProductDetails(state, payload) {
      state.product_details = payload;
      state.product_details_loading = false;
    },
    setLoadingProductDetails(state, payload) {
      state.product_details_loading = payload;
    },
    setShowEditForm(state, payload) {
      state.show_edit_form = payload;
    }
  },
  getters: {
    getProductDetails: state => state.product_details,
    getLoadingProductData: state => state.product_details_loading,
    getShowEditForm: state => state.show_edit_form
  }
};