import Vue from "vue";
import VueRouter from "vue-router";
import Dashboard from "../views/Dashboard/Dashboard.vue";
import Users from "../views/Users/Users.vue";
import NewUser from "../views/Users/NewUser.vue";
import Products from "../views/Products/Products.vue";
import Distributors from "../views/Distributors/Distributors.vue";
import Data from "../views/Data/Data.vue";
import SubscriptionSubmissions from "../views/SubscriptionSubmissions/SubscriptionSubmissions.vue";
import Session from "../views/Sessions/Sessions.vue";
import ProductDetails from "../views/ProductDetails/ProductDetails.vue";
import Api from "../views/Api/Api.vue";
import Password from "../views/Settings/Password/Password.vue";

Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard
  },
  {
    path: "/users",
    name: "Users",
    component: Users
  }, {
    path: "/users/new",
    name: "NewUser",
    component: NewUser
  },
  {
    path: "/products",
    name: "Products",
    component: Products
  },
  {
    path: "/product_details/:id",
    name: "ProductDetail",
    component: ProductDetails
  },
  {
    path: "/distributors",
    name: "Distributors",
    component: Distributors
  },
  {
    path: "/data",
    name: "Data",
    component: Data
  },
  {
    path: "/subscription_submissions/:subscription_id",
    name: "SubscriptionSubmissions",
    component: SubscriptionSubmissions,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/session/:id",
    name: "Session",
    component: Session,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/api",
    name: "Api",
    component: Api,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/settings/password",
    name: "Password",
    component: Password,
    meta: {
      requiresAuth: true
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
