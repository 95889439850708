var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { sm: "12" } }, [
                _c(
                  "div",
                  {},
                  [
                    _c(
                      "v-form",
                      {
                        ref: "form",
                        staticClass: "pb-5 space-y-6",
                        attrs: { "lazy-validation": "" }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "pa-5" },
                          [
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "8" } },
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        type: "text",
                                        name: "config_name",
                                        id: "config_name",
                                        label: _vm.$t(
                                          "tenant.page.products.new.product"
                                        ),
                                        "single-line": "",
                                        outlined: "",
                                        rules: [_vm.requiredRule]
                                      },
                                      model: {
                                        value: _vm.data.name,
                                        callback: function($$v) {
                                          _vm.$set(_vm.data, "name", $$v)
                                        },
                                        expression: "data.name"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "4" } },
                                  [
                                    _c("v-checkbox", {
                                      staticClass: "mx-2",
                                      attrs: {
                                        label: _vm.$t(
                                          "tenant.page.products.new.structuredfile"
                                        )
                                      },
                                      model: {
                                        value:
                                          _vm.data.accepts_structured_text_file,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.data,
                                            "accepts_structured_text_file",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "data.accepts_structured_text_file"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _vm.error
                              ? _c(
                                  "p",
                                  {
                                    staticClass:
                                      "py-2 my-2 text-xl text-center text-red-500 bg-red-200 rounded"
                                  },
                                  [_vm._v(" " + _vm._s(this.error) + " ")]
                                )
                              : _vm._e(),
                            _c(
                              "v-row",
                              [
                                _c("v-col", { attrs: { sm: "6" } }, [
                                  _c(
                                    "h3",
                                    {
                                      staticClass:
                                        "text-base font-medium text-gray-900"
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "tenant.page.products.new.productevents"
                                            )
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                ]),
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "flex items-end",
                                    attrs: { cols: "6" }
                                  },
                                  [
                                    _c("v-spacer"),
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          color: "blue lighten-2",
                                          outlined: "",
                                          small: ""
                                        },
                                        on: {
                                          click: function($event) {
                                            $event.stopPropagation()
                                            $event.preventDefault()
                                            return _vm.addEvent($event)
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "tenant.page.products.new.event.add"
                                              )
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-row",
                              { attrs: { justify: "center" } },
                              [
                                _c(
                                  "v-expansion-panels",
                                  {
                                    attrs: { accordion: "" },
                                    model: {
                                      value: _vm.expander,
                                      callback: function($$v) {
                                        _vm.expander = $$v
                                      },
                                      expression: "expander"
                                    }
                                  },
                                  _vm._l(_vm.events, function(event, eventKey) {
                                    return _c(
                                      "v-expansion-panel",
                                      { key: eventKey },
                                      [
                                        _c("v-expansion-panel-header", [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "flex justify-between pr-6"
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    event.name ||
                                                      _vm.$t(
                                                        "tenant.page.products.new.event.namehint"
                                                      )
                                                  )
                                                )
                                              ]),
                                              eventKey == _vm.expander
                                                ? _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        color:
                                                          "blue-grey lighten-3",
                                                        icon: "",
                                                        dense: ""
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          $event.preventDefault()
                                                          return _vm.removeEvent(
                                                            eventKey
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c("v-icon", [
                                                        _vm._v("remove")
                                                      ])
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        ]),
                                        _c(
                                          "v-expansion-panel-content",
                                          [
                                            _c(
                                              "v-row",
                                              [
                                                _c(
                                                  "v-col",
                                                  { attrs: { cols: "12" } },
                                                  [
                                                    _c("v-text-field", {
                                                      staticClass: "ma-0",
                                                      attrs: {
                                                        type: "text",
                                                        name: "event_name",
                                                        id: "event_name",
                                                        label: _vm.$t(
                                                          "tenant.page.products.new.event.namehint"
                                                        ),
                                                        rules: [
                                                          _vm.requiredRule
                                                        ],
                                                        required: "",
                                                        "single-line": "",
                                                        outlined: "",
                                                        dense: ""
                                                      },
                                                      model: {
                                                        value: event.name,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            event,
                                                            "name",
                                                            $$v
                                                          )
                                                        },
                                                        expression: "event.name"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "flex justify-between"
                                              },
                                              [
                                                _c(
                                                  "h4",
                                                  {
                                                    staticClass:
                                                      "text-base font-medium text-gray-900"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "tenant.page.products.new.fields.title"
                                                          )
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                ),
                                                event.fields.length == 0
                                                  ? _c(
                                                      "v-btn",
                                                      {
                                                        attrs: {
                                                          color:
                                                            "blue-grey lighten-2",
                                                          outlined: "",
                                                          small: ""
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                            $event.preventDefault()
                                                            return _vm.addField(
                                                              eventKey
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "tenant.page.products.new.fields.add"
                                                              )
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e()
                                              ],
                                              1
                                            ),
                                            _c(
                                              "ol",
                                              {
                                                staticClass:
                                                  "mt-3 space-y-2 divide-y-2"
                                              },
                                              _vm._l(event.fields, function(
                                                field,
                                                field_index
                                              ) {
                                                return _vm.isVisibleField(field)
                                                  ? _c(
                                                      "li",
                                                      {
                                                        key: field_index,
                                                        staticClass: "py-2 my-2"
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "flex items-start space-x-3"
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "md:w-1/2"
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "flex flex-col md:justify-between md:space-x-2 md:flex-row md:items-start"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-text-field",
                                                                      {
                                                                        staticClass:
                                                                          "pa-1 ma-0",
                                                                        attrs: {
                                                                          label: _vm.$t(
                                                                            "tenant.page.products.new.fields.fieldname"
                                                                          ),
                                                                          required:
                                                                            "",
                                                                          rules: [
                                                                            _vm.requiredRule
                                                                          ],
                                                                          "single-line":
                                                                            "",
                                                                          small:
                                                                            "",
                                                                          outlined:
                                                                            "",
                                                                          dense:
                                                                            ""
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            field.name,
                                                                          callback: function(
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              field,
                                                                              "name",
                                                                              $$v
                                                                            )
                                                                          },
                                                                          expression:
                                                                            "field.name"
                                                                        }
                                                                      }
                                                                    ),
                                                                    _c(
                                                                      "v-checkbox",
                                                                      {
                                                                        staticClass:
                                                                          "mx-2",
                                                                        attrs: {
                                                                          label: _vm.$t(
                                                                            "tenant.page.products.new.fields.required"
                                                                          )
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            field.required,
                                                                          callback: function(
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              field,
                                                                              "required",
                                                                              $$v
                                                                            )
                                                                          },
                                                                          expression:
                                                                            "field.required"
                                                                        }
                                                                      }
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "md:w-1/2"
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "flex items-start justify-end"
                                                                  },
                                                                  [
                                                                    _vm.data
                                                                      .accepts_structured_text_file
                                                                      ? _c(
                                                                          "div",
                                                                          [
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "flex flex-col space-y-2 md:flex-row md:space-x-2 md:space-y-0"
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "w-full md:w-1/2"
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-text-field",
                                                                                      {
                                                                                        attrs: {
                                                                                          label: _vm.$t(
                                                                                            "tenant.page.products.new.fields.length"
                                                                                          ),
                                                                                          required:
                                                                                            "",
                                                                                          "single-line":
                                                                                            "",
                                                                                          outlined:
                                                                                            "",
                                                                                          dense:
                                                                                            "",
                                                                                          "hide-details":
                                                                                            "",
                                                                                          rules: [
                                                                                            _vm.requiredRule
                                                                                          ]
                                                                                        },
                                                                                        model: {
                                                                                          value:
                                                                                            field.length,
                                                                                          callback: function(
                                                                                            $$v
                                                                                          ) {
                                                                                            _vm.$set(
                                                                                              field,
                                                                                              "length",
                                                                                              $$v
                                                                                            )
                                                                                          },
                                                                                          expression:
                                                                                            "field.length"
                                                                                        }
                                                                                      }
                                                                                    )
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "w-full md:w-1/2"
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-text-field",
                                                                                      {
                                                                                        attrs: {
                                                                                          label: _vm.$t(
                                                                                            "tenant.page.products.new.fields.sequencenumber"
                                                                                          ),
                                                                                          required:
                                                                                            "",
                                                                                          "single-line":
                                                                                            "",
                                                                                          outlined:
                                                                                            "",
                                                                                          dense:
                                                                                            "",
                                                                                          "hide-details":
                                                                                            "",
                                                                                          rules: [
                                                                                            _vm.requiredRule
                                                                                          ]
                                                                                        },
                                                                                        model: {
                                                                                          value:
                                                                                            field.sequence_number,
                                                                                          callback: function(
                                                                                            $$v
                                                                                          ) {
                                                                                            _vm.$set(
                                                                                              field,
                                                                                              "sequence_number",
                                                                                              $$v
                                                                                            )
                                                                                          },
                                                                                          expression:
                                                                                            "field.sequence_number"
                                                                                        }
                                                                                      }
                                                                                    )
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "w-2/12 pl-2"
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-btn",
                                                                          {
                                                                            attrs: {
                                                                              color:
                                                                                "blue-grey lighten-3 ma-0 px-0",
                                                                              outlined:
                                                                                "",
                                                                              dense:
                                                                                "",
                                                                              "x-small":
                                                                                ""
                                                                            },
                                                                            on: {
                                                                              click: function(
                                                                                $event
                                                                              ) {
                                                                                $event.stopPropagation()
                                                                                $event.preventDefault()
                                                                                return _vm.removeField(
                                                                                  eventKey,
                                                                                  field_index
                                                                                )
                                                                              }
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-icon",
                                                                              {
                                                                                attrs: {
                                                                                  left:
                                                                                    "",
                                                                                  small:
                                                                                    ""
                                                                                }
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "remove"
                                                                                )
                                                                              ]
                                                                            ),
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "tenant.page.products.new.fields.field"
                                                                                  )
                                                                                ) +
                                                                                " "
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "mt-2"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "flex flex-col items-baseline justify-between md:space-x-4 md:flex-row"
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "h4",
                                                                          {
                                                                            staticClass:
                                                                              "text-base text-gray-900"
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "tenant.page.products.new.validations.title"
                                                                                  )
                                                                                ) +
                                                                                " "
                                                                            )
                                                                          ]
                                                                        ),
                                                                        field
                                                                          .validations
                                                                          .length ==
                                                                        0
                                                                          ? _c(
                                                                              "v-btn",
                                                                              {
                                                                                attrs: {
                                                                                  color:
                                                                                    "blue-grey lighten-2",
                                                                                  icon:
                                                                                    "",
                                                                                  small:
                                                                                    ""
                                                                                },
                                                                                on: {
                                                                                  click: function(
                                                                                    $event
                                                                                  ) {
                                                                                    $event.stopPropagation()
                                                                                    $event.preventDefault()
                                                                                    return _vm.addvalidation(
                                                                                      eventKey,
                                                                                      field_index
                                                                                    )
                                                                                  }
                                                                                }
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "v-icon",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "add"
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              ],
                                                                              1
                                                                            )
                                                                          : _vm._e()
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _vm._l(
                                                                      field.validations,
                                                                      function(
                                                                        selected_validation,
                                                                        ind
                                                                      ) {
                                                                        return _c(
                                                                          "ol",
                                                                          {
                                                                            key: ind,
                                                                            staticClass:
                                                                              "px-0 py-0 mt-2"
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "li",
                                                                              {
                                                                                staticClass:
                                                                                  "flex items-baseline justify-between w-full space-x-1"
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "flex flex-col w-full py-0 my-0 md:space-x-2 md:flex-row"
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "md:w-1/2"
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "v-select",
                                                                                          {
                                                                                            staticClass:
                                                                                              "w-full ma-0",
                                                                                            attrs: {
                                                                                              items:
                                                                                                _vm.validations,
                                                                                              label: _vm.$t(
                                                                                                "tenant.page.products.new.validations.type"
                                                                                              ),
                                                                                              "item-text":
                                                                                                "name",
                                                                                              "single-line":
                                                                                                "",
                                                                                              outlined:
                                                                                                "",
                                                                                              dense:
                                                                                                "",
                                                                                              "hide-details":
                                                                                                ""
                                                                                            },
                                                                                            on: {
                                                                                              change: function(
                                                                                                $event
                                                                                              ) {
                                                                                                return _vm.handleChange(
                                                                                                  $event,
                                                                                                  eventKey,
                                                                                                  field_index,
                                                                                                  ind
                                                                                                )
                                                                                              }
                                                                                            },
                                                                                            model: {
                                                                                              value:
                                                                                                selected_validation.type,
                                                                                              callback: function(
                                                                                                $$v
                                                                                              ) {
                                                                                                _vm.$set(
                                                                                                  selected_validation,
                                                                                                  "type",
                                                                                                  $$v
                                                                                                )
                                                                                              },
                                                                                              expression:
                                                                                                "selected_validation.type"
                                                                                            }
                                                                                          }
                                                                                        )
                                                                                      ],
                                                                                      1
                                                                                    ),
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "md:w-1/2"
                                                                                      },
                                                                                      [
                                                                                        selected_validation.has_attribute
                                                                                          ? _c(
                                                                                              "div",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "flex space-x-2"
                                                                                              },
                                                                                              _vm._l(
                                                                                                selected_validation.attribute,
                                                                                                function(
                                                                                                  attribute,
                                                                                                  counter
                                                                                                ) {
                                                                                                  return _c(
                                                                                                    "div",
                                                                                                    {
                                                                                                      key: counter,
                                                                                                      staticClass:
                                                                                                        "w-full"
                                                                                                    },
                                                                                                    [
                                                                                                      attribute.value_type ==
                                                                                                      "selector"
                                                                                                        ? _c(
                                                                                                            "v-select",
                                                                                                            {
                                                                                                              staticClass:
                                                                                                                "w-full ma-0",
                                                                                                              attrs: {
                                                                                                                items:
                                                                                                                  attribute.options,
                                                                                                                label:
                                                                                                                  attribute.label,
                                                                                                                "single-line":
                                                                                                                  "",
                                                                                                                "full-width":
                                                                                                                  "",
                                                                                                                outlined:
                                                                                                                  "",
                                                                                                                dense:
                                                                                                                  "",
                                                                                                                "hide-details":
                                                                                                                  ""
                                                                                                              },
                                                                                                              model: {
                                                                                                                value:
                                                                                                                  selected_validation.operator,
                                                                                                                callback: function(
                                                                                                                  $$v
                                                                                                                ) {
                                                                                                                  _vm.$set(
                                                                                                                    selected_validation,
                                                                                                                    "operator",
                                                                                                                    $$v
                                                                                                                  )
                                                                                                                },
                                                                                                                expression:
                                                                                                                  "\n                                                selected_validation.operator\n                                              "
                                                                                                              }
                                                                                                            }
                                                                                                          )
                                                                                                        : _vm._e(),
                                                                                                      attribute.value_type ==
                                                                                                      "input"
                                                                                                        ? _c(
                                                                                                            "v-text-field",
                                                                                                            {
                                                                                                              staticClass:
                                                                                                                "w-full ma-0",
                                                                                                              attrs: {
                                                                                                                label:
                                                                                                                  attribute.label,
                                                                                                                "single-line":
                                                                                                                  "",
                                                                                                                "full-width":
                                                                                                                  "",
                                                                                                                outlined:
                                                                                                                  "",
                                                                                                                dense:
                                                                                                                  "",
                                                                                                                "hide-details":
                                                                                                                  ""
                                                                                                              },
                                                                                                              model: {
                                                                                                                value:
                                                                                                                  selected_validation.input,
                                                                                                                callback: function(
                                                                                                                  $$v
                                                                                                                ) {
                                                                                                                  _vm.$set(
                                                                                                                    selected_validation,
                                                                                                                    "input",
                                                                                                                    $$v
                                                                                                                  )
                                                                                                                },
                                                                                                                expression:
                                                                                                                  "\n                                                selected_validation.input\n                                              "
                                                                                                              }
                                                                                                            }
                                                                                                          )
                                                                                                        : _vm._e()
                                                                                                    ],
                                                                                                    1
                                                                                                  )
                                                                                                }
                                                                                              ),
                                                                                              0
                                                                                            )
                                                                                          : _vm._e()
                                                                                      ]
                                                                                    )
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "flex md:w-2/12"
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-btn",
                                                                                      {
                                                                                        attrs: {
                                                                                          color:
                                                                                            "blue-grey lighten-2",
                                                                                          icon:
                                                                                            "",
                                                                                          dense:
                                                                                            "",
                                                                                          small:
                                                                                            ""
                                                                                        },
                                                                                        on: {
                                                                                          click: function(
                                                                                            $event
                                                                                          ) {
                                                                                            $event.stopPropagation()
                                                                                            $event.preventDefault()
                                                                                            return _vm.removeValidation(
                                                                                              eventKey,
                                                                                              field_index,
                                                                                              ind
                                                                                            )
                                                                                          }
                                                                                        }
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "v-icon",
                                                                                          {
                                                                                            attrs: {
                                                                                              dense:
                                                                                                "",
                                                                                              small:
                                                                                                ""
                                                                                            }
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              "remove"
                                                                                            )
                                                                                          ]
                                                                                        )
                                                                                      ],
                                                                                      1
                                                                                    ),
                                                                                    ind ==
                                                                                    field
                                                                                      .validations
                                                                                      .length -
                                                                                      1
                                                                                      ? _c(
                                                                                          "v-btn",
                                                                                          {
                                                                                            attrs: {
                                                                                              color:
                                                                                                "blue-grey lighten-2",
                                                                                              icon:
                                                                                                "",
                                                                                              dense:
                                                                                                "",
                                                                                              small:
                                                                                                ""
                                                                                            },
                                                                                            on: {
                                                                                              click: function(
                                                                                                $event
                                                                                              ) {
                                                                                                $event.stopPropagation()
                                                                                                $event.preventDefault()
                                                                                                return _vm.addvalidation(
                                                                                                  eventKey,
                                                                                                  field_index
                                                                                                )
                                                                                              }
                                                                                            }
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "v-icon",
                                                                                              {
                                                                                                attrs: {
                                                                                                  dense:
                                                                                                    "",
                                                                                                  small:
                                                                                                    ""
                                                                                                }
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "add"
                                                                                                )
                                                                                              ]
                                                                                            )
                                                                                          ],
                                                                                          1
                                                                                        )
                                                                                      : _vm._e()
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        )
                                                                      }
                                                                    )
                                                                  ],
                                                                  2
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e()
                                              }),
                                              0
                                            ),
                                            event.fields.length > 0
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "flex justify-end pt-2 mt-3"
                                                  },
                                                  [
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        attrs: {
                                                          color:
                                                            "blue-grey lighten-2",
                                                          outlined: "",
                                                          small: ""
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                            $event.preventDefault()
                                                            return _vm.addField(
                                                              eventKey
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "tenant.page.products.new.fields.add"
                                                              )
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  }),
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-row",
                              {
                                staticClass: "space-x-5 ma-3",
                                attrs: { justify: "end" }
                              },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      color: "blue-grey lighten-2",
                                      outlined: ""
                                    },
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        $event.preventDefault()
                                        return _vm.addEvent($event)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "tenant.page.products.new.event.add"
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      color: "blue-gray lighten-2",
                                      outlined: ""
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.closeEdit()
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("tenant.page.products.cancel")
                                        ) +
                                        " "
                                    )
                                  ]
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "primary",
                                    attrs: { color: "primary" },
                                    on: { click: _vm.submitData }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "tenant.page.products.new.submit"
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    )
                  ],
                  1
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }