var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        { attrs: { outlined: "" } },
        [
          _c(
            "v-card-title",
            [
              _c("v-col", { attrs: { cols: "10" } }, [
                _vm._v(_vm._s(_vm.$t("tenant.page.session.title")))
              ]),
              _c(
                "v-col",
                { attrs: { cols: "1" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        justify: "end",
                        disabled: _vm.downloaded
                      },
                      on: { click: _vm.getData }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("tenant.page.session.buttons.download")
                          ) +
                          " "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              _c("v-simple-table", {
                attrs: { flat: "" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function() {
                      return [
                        _c("thead", [
                          _c("tr", [
                            _c("th", { staticClass: "text-left" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "tenant.page.session.headers.product"
                                    )
                                  ) +
                                  " "
                              )
                            ]),
                            _c("th", { staticClass: "text-left" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("tenant.page.session.headers.row")
                                  ) +
                                  " "
                              )
                            ]),
                            _c("th", { staticClass: "text-left" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "tenant.page.session.headers.rowerrors"
                                    )
                                  ) +
                                  " "
                              )
                            ]),
                            _c("th", { staticClass: "text-left" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("tenant.page.session.headers.reads")
                                  ) +
                                  " "
                              )
                            ]),
                            _c("th", { staticClass: "text-left" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "tenant.page.session.headers.downloaded"
                                    )
                                  ) +
                                  " "
                              )
                            ])
                          ])
                        ]),
                        _c("tbody", [
                          _c("tr", [
                            _c("td", [
                              _vm._v(_vm._s(_vm.session.product_name))
                            ]),
                            _c("td", [
                              _vm._v(
                                _vm._s(_vm.$n(_vm.session.total_document_count))
                              )
                            ]),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm.$n(
                                    _vm.session.failed_validation_document_count
                                  )
                                )
                              )
                            ]),
                            _c("td", [
                              _vm._v(_vm._s(_vm.$n(_vm.session.reads)))
                            ]),
                            _c("td", [_vm._v(_vm._s(_vm.session.downloaded))])
                          ])
                        ])
                      ]
                    },
                    proxy: true
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-card",
        { staticClass: "my-4", attrs: { outlined: "" } },
        [
          _c(
            "v-card-title",
            [
              _c(
                "v-row",
                { staticClass: "mx-4" },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("tenant.page.session.datasession")) +
                      " "
                  ),
                  _c("v-spacer")
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              _c("v-simple-table", {
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function() {
                      return [
                        _c("thead", [
                          _c(
                            "tr",
                            _vm._l(_vm.getHeaders, function(header, index) {
                              return _c(
                                "th",
                                { key: index, staticClass: "text-left" },
                                [_vm._v(" " + _vm._s(header) + " ")]
                              )
                            }),
                            0
                          ),
                          _vm.getLoadingSessionData
                            ? _c("tr", { staticClass: "p-0 m-0" }, [
                                _c(
                                  "td",
                                  {
                                    staticClass: "p-0 m-0",
                                    attrs: { colspan: _vm.getHeaders.length }
                                  },
                                  [
                                    _c("v-progress-linear", {
                                      attrs: { indeterminate: "" }
                                    })
                                  ],
                                  1
                                )
                              ])
                            : _vm._e()
                        ]),
                        _c(
                          "tbody",
                          _vm._l(_vm.getSessionData, function(event) {
                            return _c(
                              "tr",
                              { key: event.bmm_dex_session_event_id },
                              _vm._l(_vm.getHeaders, function(header, index) {
                                return _c(
                                  "td",
                                  {
                                    key: index,
                                    class: {
                                      "text-red-600":
                                        event.bmm_dex_has_failed_validation
                                    }
                                  },
                                  [
                                    _c("span", { staticClass: "block" }, [
                                      _vm._v(_vm._s(event[header].value))
                                    ]),
                                    _c(
                                      "span",
                                      { staticClass: "block my-1 text-sm" },
                                      [_vm._v(_vm._s(event[header].message))]
                                    )
                                  ]
                                )
                              }),
                              0
                            )
                          }),
                          0
                        ),
                        _c("tfoot", [
                          _c("tr", [
                            _c(
                              "td",
                              { attrs: { colspan: _vm.getHeaders.length } },
                              [
                                _c(
                                  "div",
                                  { staticClass: "flex justify-end space-x-4" },
                                  [
                                    _vm.getPagination.before
                                      ? _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              outlined: "",
                                              small: "",
                                              color: "grey"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.downloadSessionEvents(
                                                  {
                                                    before:
                                                      _vm.getPagination.before
                                                  }
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "$vuetify.pagination.ariaLabel.previous"
                                                  )
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.getPagination.after
                                      ? _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              outlined: "",
                                              small: "",
                                              color: "grey"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.downloadSessionEvents(
                                                  {
                                                    after:
                                                      _vm.getPagination.after
                                                  }
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "$vuetify.pagination.ariaLabel.next"
                                                  )
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ]
                            )
                          ])
                        ])
                      ]
                    },
                    proxy: true
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }