<template>
  <div>
    <div class="shadow sm:rounded-md sm:overflow-hidden">
      <div class="flex px-4 py-5 bg-white border-b border-gray-200 sm:p-6">
        <h3 class="text-lg font-medium text-gray-900">
          {{ $t("tenant.page.products.title") }}
        </h3>
        <v-spacer></v-spacer>
        <ProductsDialog class="mr-2" v-if="!isViewer" />
      </div>
      <div class="pb-5 space-y-6 bg-white">
        <v-data-table
          :loading="getLoadingProducts"
          :loading-text="$t('tenant.page.products.progress')"
          :headers="headers"
          :items="getProducts"
          :items-per-page="30"
          flat
        >
          <template v-slot:item.subscriptions="{ item }">
            <v-btn
              text
              small
              color="primary"
              v-on:click="openSubdialogue(item.id, item.name)"
            >
              {{ $t("tenant.page.products.headers.subscriptions") }}
            </v-btn>
          </template>

          <template v-slot:item.events="{ item }">
            <span>{{ $n(Object.keys(item.events).length) }}</span>
          </template>

          <template v-slot:item.name="{ item }">
            <v-btn
              text
              small
              color="primary"
              v-on:click="openProductdetail(item.id)"
            >
              {{ item.name }}
            </v-btn>
          </template>
        </v-data-table>
      </div>
    </div>
    <v-dialog v-model="subdialog" max-width="90vw">
      <v-data-table
        :headers="subscriptionHeaders"
        :loading="getProductSubsLoading"
        :loading-text="$t('tenant.page.products.subscriptions.progress')"
        :items="getProductSubs"
      >
        <template v-slot:item.prod_name>
          {{ selectedProduct }}
        </template>
      </v-data-table>
    </v-dialog>
  </div>
</template>

<script>
import ProductsDialog from "./NewProductDialog.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    ProductsDialog,
  },
  data() {
    return {
      selectedProduct: "",
      subdialog: false,
    };
  },
  methods: {
    openSubdialogue(product_id, product_name) {
      this.selectedProduct = product_name;
      this.$store.dispatch("getProductSubs", product_id);
      this.subdialog = true;
    },
    openProductdetail(product_id) {
      console.log(product_id);
      this.$router.push({
        name: "ProductDetail",
        params: { id: product_id },
      });
    },
  },
  computed: {
    ...mapGetters([
      "getProducts",
      "getLoadingProducts",
      "getProductSubsLoading",
      "getProductSubs",
      "isViewer",
    ]),
    headers() {
      return [
        {
          text: this.$t("tenant.page.products.headers.name"),
          value: "name",
        },
        {
          text: this.$t("tenant.page.products.headers.events"),
          value: "events",
          sortable: false,
        },
        {
          text: this.$t("tenant.page.products.headers.subscriptions"),
          value: "subscriptions",
          sortable: false,
        },
      ];
    },
    subscriptionHeaders() {
      return [
        {
          text: this.$t("tenant.page.products.subscriptions.headers.product"),
          value: "prod_name",
        },
        {
          text: this.$t("tenant.page.products.subscriptions.headers.customer"),
          value: "customer_name",
        },
      ];
    },
  },
};
</script>

<style></style>
