var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "p-5 space-y-6" }, [
    _c("h2", { staticClass: "text-lg font-bold" }, [
      _vm._v(" " + _vm._s(_vm.product.name) + " ")
    ]),
    _c(
      "div",
      [
        _c("h3", { staticClass: "mb-4 text-base font-medium" }, [
          _vm._v(
            " " + _vm._s(_vm.$t("tenant.page.products.new.event.title")) + " "
          )
        ]),
        _c(
          "v-expansion-panels",
          _vm._l(this.product.events, function(eventdetails, event, i) {
            return _c(
              "v-expansion-panel",
              { key: i },
              [
                _c(
                  "v-expansion-panel-header",
                  { staticClass: "grey lighten-5" },
                  [
                    _c("h4", { staticClass: "text-base font-normal" }, [
                      _vm._v(_vm._s(event))
                    ])
                  ]
                ),
                _c(
                  "v-expansion-panel-content",
                  [
                    _c("h4", { staticClass: "mt-4 text-sm font-bold" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("tenant.page.products.new.fields.title")
                          ) +
                          " "
                      )
                    ]),
                    _c(
                      "v-expansion-panels",
                      {
                        staticClass: "mt-4 divide-y-2",
                        attrs: { accordion: "", flat: "" }
                      },
                      _vm._l(_vm.visibleFields(eventdetails), function(
                        fieldDetails,
                        k
                      ) {
                        return _c(
                          "v-expansion-panel",
                          { key: k },
                          [
                            _c("v-expansion-panel-header", [
                              _vm._v(" " + _vm._s(fieldDetails.name) + " ")
                            ]),
                            _c(
                              "v-expansion-panel-content",
                              [
                                _c(
                                  "v-row",
                                  [
                                    _c("v-col", { attrs: { cols: "3" } }, [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "tenant.page.products.new.fields.required"
                                            )
                                          ) +
                                            ": " +
                                            _vm._s(
                                              fieldDetails.nullable
                                                ? "False"
                                                : "True"
                                            )
                                        )
                                      ])
                                    ]),
                                    _c("v-col", { attrs: { cols: "9" } }, [
                                      fieldDetails.text_file_config
                                        ? _c(
                                            "div",
                                            { staticClass: "mb-3" },
                                            [
                                              _c("h5", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "tenant.page.products.new.fields.structured"
                                                      )
                                                    ) +
                                                    " "
                                                )
                                              ]),
                                              _c("v-simple-table", {
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function() {
                                                        return [
                                                          _c("thead", [
                                                            _c("tr", [
                                                              _c("th", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "tenant.page.products.new.fields.length"
                                                                      )
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]),
                                                              _c("th", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "tenant.page.products.new.fields.sequencenumber"
                                                                      )
                                                                    ) +
                                                                    " "
                                                                )
                                                              ])
                                                            ])
                                                          ]),
                                                          _c("tbody", [
                                                            _c("tr", [
                                                              _c("td", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      fieldDetails
                                                                        .text_file_config
                                                                        .length
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]),
                                                              _c("td", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      fieldDetails
                                                                        .text_file_config
                                                                        .sequence_number
                                                                    ) +
                                                                    " "
                                                                )
                                                              ])
                                                            ])
                                                          ])
                                                        ]
                                                      },
                                                      proxy: true
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      fieldDetails.validations &&
                                      fieldDetails.validations.length != 0
                                        ? _c(
                                            "div",
                                            [
                                              _c("h5", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "tenant.page.products.new.validations.title"
                                                      )
                                                    ) +
                                                    " "
                                                )
                                              ]),
                                              _c("v-data-table", {
                                                attrs: {
                                                  "hide-default-footer": "",
                                                  headers:
                                                    _vm.validationHeaders,
                                                  items:
                                                    fieldDetails.validations
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ])
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      }),
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          }),
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }