var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "shadow sm:rounded-md sm:overflow-hidden" }, [
        _c(
          "div",
          {
            staticClass:
              "flex px-4 py-5 bg-white border-b border-gray-200 sm:p-6"
          },
          [
            _c("h3", { staticClass: "text-lg font-medium text-gray-900" }, [
              _vm._v(" " + _vm._s(_vm.$t("tenant.page.data.title")) + " ")
            ])
          ]
        ),
        _c(
          "div",
          { staticClass: "pb-5 space-y-6 bg-white" },
          [
            _c("v-data-table", {
              attrs: {
                loading: _vm.getLoadingProducts,
                "loading-text": _vm.$t("tenant.page.data.progress"),
                headers: _vm.headers,
                items: _vm.getProducts,
                "items-per-page": 30,
                flat: ""
              },
              scopedSlots: _vm._u([
                {
                  key: "item.subscriptions",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary", outlined: "", small: "" },
                          on: {
                            click: function($event) {
                              return _vm.openSubdialogue(item.id, item.name)
                            }
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("tenant.page.data.headers.subscriptions")
                              ) +
                              " "
                          )
                        ]
                      )
                    ]
                  }
                }
              ])
            })
          ],
          1
        )
      ]),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "90vw" },
          model: {
            value: _vm.subdialog,
            callback: function($$v) {
              _vm.subdialog = $$v
            },
            expression: "subdialog"
          }
        },
        [
          _c("v-data-table", {
            attrs: {
              headers: _vm.subscriptionHeaders,
              loading: _vm.getProductSubsLoading,
              "loading-text": _vm.$t("tenant.page.data.progress"),
              items: _vm.getProductSubs
            },
            scopedSlots: _vm._u([
              {
                key: "item.prod_name",
                fn: function() {
                  return [_vm._v(" " + _vm._s(_vm.selectedProduct) + " ")]
                },
                proxy: true
              },
              {
                key: "item.data",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: {
                            name: "SubscriptionSubmissions",
                            params: {
                              subscription_id: "" + item.subscription_id
                            }
                          }
                        }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "tenant.page.data.subscriptionheaders.data"
                              )
                            ) +
                            " "
                        )
                      ]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }