<template>
  <div>
    <v-container>
      <v-row>
        <v-col sm="12">
          <div class="">
            <v-form ref="form" lazy-validation class="pb-5 space-y-6">
              <div class="pa-5">
                <v-row>
                  <v-col cols="8">
                    <v-text-field
                      v-model="data.name"
                      type="text"
                      name="config_name"
                      id="config_name"
                      :label="$t('tenant.page.products.new.product')"
                      single-line
                      outlined
                      :rules="[requiredRule]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-checkbox
                      v-model="data.accepts_structured_text_file"
                      class="mx-2"
                      :label="$t('tenant.page.products.new.structuredfile')"
                    ></v-checkbox>
                  </v-col>
                </v-row>

                <p
                  class="py-2 my-2 text-xl text-center text-red-500 bg-red-200 rounded"
                  v-if="error"
                >
                  {{ this.error }}
                </p>

                <v-row>
                  <v-col sm="6">
                    <h3 class="text-base font-medium text-gray-900">
                      {{ $t("tenant.page.products.new.productevents") }}
                    </h3>
                  </v-col>
                  <v-col cols="6" class="flex items-end">
                    <v-spacer></v-spacer>
                    <v-btn
                      color="blue lighten-2"
                      outlined
                      small
                      v-on:click.stop.prevent="addEvent"
                      v-if="events.length == 0"
                    >
                      {{ $t("tenant.page.products.new.event.add") }}
                    </v-btn>
                  </v-col>
                </v-row>

                <v-row justify="center">
                  <v-expansion-panels accordion v-model="expander">
                    <v-expansion-panel
                      v-for="(event, eventKey) in events"
                      v-bind:key="eventKey"
                    >
                      <v-expansion-panel-header>
                        <div class="flex justify-between pr-6">
                          <span>{{
                            event.name ||
                            $t("tenant.page.products.new.event.namehint")
                          }}</span>
                          <v-btn
                            color="blue-grey lighten-3"
                            icon
                            dense
                            v-on:click.stop.prevent="removeEvent(eventKey)"
                            v-if="eventKey == expander"
                          >
                            <v-icon>remove</v-icon>
                          </v-btn>
                        </div>
                      </v-expansion-panel-header>

                      <v-expansion-panel-content>
                        <v-row>
                          <!-- Event main fields -->
                          <v-col cols="12">
                            <v-text-field
                              v-model="event.name"
                              type="text"
                              name="event_name"
                              id="event_name"
                              :label="
                                $t('tenant.page.products.new.event.namehint')
                              "
                              class="ma-0"
                              :rules="[requiredRule]"
                              required
                              single-line
                              outlined
                              dense
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <div class="flex justify-between">
                          <!-- Fields title -->
                          <h4 class="text-base font-medium text-gray-900">
                            {{ $t("tenant.page.products.new.fields.title") }}
                          </h4>

                          <!-- Add a new field block. Shows only when fields.length == 0 -->
                          <v-btn
                            color="blue-grey lighten-2"
                            outlined
                            small
                            v-on:click.stop.prevent="addField(eventKey)"
                            v-if="event.fields.length == 0"
                          >
                            {{ $t("tenant.page.products.new.fields.add") }}
                          </v-btn>
                        </div>

                        <!-- Wrapper for Fields List -->
                        <ol class="mt-3 divide-y-2">
                          <!-- Single Field -->
                          <li
                            class="py-2 my-2"
                            v-for="(field, field_index) in event.fields"
                            v-bind:key="field_index"
                          >
                            <div class="flex items-start space-x-3">
                              <div class="md:w-1/2">
                                <div
                                  class="flex flex-col md:justify-between md:space-x-2 md:flex-row md:items-start"
                                >
                                  <v-text-field
                                    :label="
                                      $t(
                                        'tenant.page.products.new.fields.fieldname'
                                      )
                                    "
                                    v-model="field.fieldName"
                                    class="pa-1 ma-0"
                                    required
                                    :rules="[requiredRule]"
                                    single-line
                                    small
                                    outlined
                                    dense
                                  ></v-text-field>

                                  <v-checkbox
                                    v-model="field.required"
                                    class="mx-2"
                                    :label="
                                      $t(
                                        'tenant.page.products.new.fields.required'
                                      )
                                    "
                                  ></v-checkbox>
                                </div>
                              </div>
                              <div class="md:w-1/2">
                                <div class="flex items-start justify-end">
                                  <div v-if="data.accepts_structured_text_file">
                                    <div
                                      class="flex flex-col space-y-2 md:flex-row md:space-x-2 md:space-y-0"
                                    >
                                      <div class="w-full md:w-1/2">
                                        <v-text-field
                                          :label="
                                            $t(
                                              'tenant.page.products.new.fields.length'
                                            )
                                          "
                                          v-model="field.length"
                                          required
                                          single-line
                                          outlined
                                          dense
                                          hide-details
                                          :rules="[requiredRule]"
                                        ></v-text-field>
                                      </div>
                                      <div class="w-full md:w-1/2">
                                        <v-text-field
                                          :label="
                                            $t(
                                              'tenant.page.products.new.fields.sequencenumber'
                                            )
                                          "
                                          v-model="field.sequence_number"
                                          required
                                          single-line
                                          outlined
                                          dense
                                          hide-details
                                          :rules="[requiredRule]"
                                        ></v-text-field>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="w-2/12 pl-2">
                                    <v-btn
                                      color="blue-grey lighten-3 ma-0 px-0"
                                      outlined
                                      dense
                                      x-small
                                      v-on:click.stop.prevent="
                                        removeField(eventKey, field_index)
                                      "
                                    >
                                      <v-icon left small>remove</v-icon>
                                      {{
                                        $t(
                                          "tenant.page.products.new.fields.field"
                                        )
                                      }}
                                    </v-btn>
                                  </div>
                                </div>

                                <div class="mt-2">
                                  <div
                                    class="flex flex-col items-baseline justify-between md:space-x-4 md:flex-row"
                                  >
                                    <h4 class="text-base text-gray-900">
                                      {{
                                        $t(
                                          "tenant.page.products.new.validations.title"
                                        )
                                      }}
                                    </h4>
                                    <v-btn
                                      color="blue-grey lighten-2"
                                      icon
                                      small
                                      v-on:click.stop.prevent="
                                        addvalidation(eventKey, field_index)
                                      "
                                      v-if="field.validations.length == 0"
                                      ><v-icon>add</v-icon>
                                    </v-btn>
                                  </div>
                                  <ol
                                    class="px-0 py-0 mt-2"
                                    v-for="(
                                      selected_validation, ind
                                    ) in field.validations"
                                    :key="ind"
                                  >
                                    <li
                                      class="flex items-baseline justify-between w-full space-x-1"
                                    >
                                      <div
                                        class="flex flex-col w-full py-0 my-0 md:space-x-2 md:flex-row"
                                      >
                                        <div class="md:w-1/2">
                                          <v-select
                                            :items="validations"
                                            :label="
                                              $t(
                                                'tenant.page.products.new.validations.type'
                                              )
                                            "
                                            class="w-full ma-0"
                                            item-text="name"
                                            single-line
                                            outlined
                                            dense
                                            hide-details
                                            v-model="selected_validation.type"
                                            v-on:change="
                                              handleChange(
                                                $event,
                                                eventKey,
                                                field_index,
                                                ind
                                              )
                                            "
                                          ></v-select>
                                        </div>

                                        <!-- callback_index, -->
                                        <div class="md:w-1/2">
                                          <div
                                            class="flex space-x-2"
                                            v-if="
                                              selected_validation.has_attribute
                                            "
                                          >
                                            <div
                                              class="w-full"
                                              v-for="(
                                                attribute, counter
                                              ) in selected_validation.attribute"
                                              v-bind:key="counter"
                                            >
                                              <v-select
                                                :items="attribute.options"
                                                :label="attribute.label"
                                                class="w-full ma-0"
                                                single-line
                                                full-width
                                                outlined
                                                dense
                                                hide-details
                                                v-model="
                                                  selected_validation.operator
                                                "
                                                v-if="
                                                  attribute.value_type ==
                                                  'selector'
                                                "
                                              ></v-select>
                                              <v-text-field
                                                :label="attribute.label"
                                                class="w-full ma-0"
                                                single-line
                                                full-width
                                                outlined
                                                dense
                                                hide-details
                                                v-model="
                                                  selected_validation.input
                                                "
                                                v-if="
                                                  attribute.value_type ==
                                                  'input'
                                                "
                                              ></v-text-field>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="flex md:w-2/12">
                                        <v-btn
                                          color="blue-grey lighten-2"
                                          icon
                                          dense
                                          small
                                          v-on:click.stop.prevent="
                                            removeValidation(
                                              eventKey,
                                              field_index,
                                              ind
                                            )
                                          "
                                        >
                                          <v-icon dense small>remove</v-icon>
                                        </v-btn>
                                        <v-btn
                                          color="blue-grey lighten-2"
                                          icon
                                          dense
                                          small
                                          v-on:click.stop.prevent="
                                            addvalidation(eventKey, field_index)
                                          "
                                          v-if="
                                            ind == field.validations.length - 1
                                          "
                                        >
                                          <v-icon dense small>add</v-icon>
                                        </v-btn>
                                      </div>
                                    </li>
                                  </ol>
                                </div>
                              </div>
                            </div>
                          </li>
                          <!-- // END Single Field -->
                        </ol>
                        <!-- // END Wrapper for Fields List -->

                        <!-- This button shows only when there are fields already -->
                        <div
                          class="flex justify-end pt-2 mt-3 border-t"
                          v-if="event.fields.length > 0"
                        >
                          <v-btn
                            color="blue-grey lighten-2"
                            outlined
                            small
                            v-on:click.stop.prevent="addField(eventKey)"
                          >
                            {{ $t("tenant.page.products.new.fields.add") }}
                          </v-btn>
                        </div>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-row>
                <v-row justify="end" class="space-x-5 ma-3">
                  <v-btn
                    color="blue-grey lighten-2"
                    outlined
                    v-on:click.stop.prevent="addEvent"
                    v-if="events.length > 0"
                  >
                    {{ $t("tenant.page.products.new.event.add") }}
                  </v-btn>
                  <v-btn
                    color="primary"
                    class="primary"
                    v-on:click="submitData"
                  >
                    {{ $t("tenant.page.products.new.submit") }}
                  </v-btn>
                </v-row>
              </div>
            </v-form>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data: () => ({
    expander: 0,
    data: {},
    events: [],
    error: "",
    pairings: ["Single", "Multiple"],
    actionTypes: ["send to insurer", "send to distributor"],
  }),
  computed: {
    requiredRule() {
      return (value) =>
        !!value ||
        this.$t("validation.required", {
          value: "",
        });
    },
    lengthRule() {
      return (v) =>
        (v && v.length >= 6) ||
        this.$t("validation.string.length>=", {
          value: "",
          length: "6",
        });
    },
    validations() {
      return [
        {
          name: this.$t("tenant.page.products.new.validations.types.blank"),
          validation_type: "blankness_check",
          has_attribute: false,
        },
        {
          name: this.$t("tenant.page.products.new.validations.types.type"),
          validation_type: "type_check",
          has_attribute: true,
          attribute: [
            {
              value_type: "selector",
              label: this.$t(
                "tenant.page.products.new.validations.labels.data"
              ),
              options: ["number", "string"],
            },
          ],
        },
        {
          name: this.$t("tenant.page.products.new.validations.types.value"),
          validation_type: "number_size_check",
          has_attribute: true,
          attribute: [
            {
              value_type: "selector",
              label: this.$t(
                "tenant.page.products.new.validations.labels.operator"
              ),
              options: ["<", ">", "<=", "==", ">="],
            },
            {
              value_type: "input",
              label: this.$t(
                "tenant.page.products.new.validations.labels.value"
              ),
            },
          ],
        },
      ];
    },
  },
  methods: {
    handleChange(event, eventIndex, fieldIndex, validationIndex) {
      var sel_val = this.validations.findIndex((x) => x.name == event);
      const fieldValidation = this.events[eventIndex].fields[fieldIndex]
        .validations[validationIndex];
      const validation = Object.assign(
        fieldValidation,
        this.validations[sel_val]
      );
      this.$set(
        this.events[eventIndex].fields[fieldIndex].validations,
        validationIndex,
        validation
      );
    },
    addEvent() {
      const emptyEvent = { name: "", fields: [] };
      this.events.push(emptyEvent);
      this.expander = this.events.length - 1;
      this.hasEvents();
    },
    removeEvent(eventIndex) {
      this.events.splice(eventIndex, 1);
    },
    addField(eventIndex) {
      const emptyField = {
        fieldName: "",
        required: false,
        validations: [],
        length: "",
        sequence_number: "",
        validationsOpen: false,
      };
      this.events[eventIndex].fields.push(emptyField);
      this.eventsHaveFields();
    },
    removeField(eventIndex, fieldIndex) {
      this.events[eventIndex].fields.splice(fieldIndex, 1);
    },
    addvalidation(eventIndex, fieldIndex) {
      const emptyValidation = {
        name: "",
        operator: "",
        input: "",
        validation_type: "",
      };
      this.events[eventIndex].fields[fieldIndex].validations.push(
        emptyValidation
      );
    },
    removeValidation(eventIndex, fieldIndex, validationIndex) {
      this.events[eventIndex].fields[fieldIndex].validations.splice(
        validationIndex,
        1
      );
    },
    hasEvents() {
      const errorMessage = "Product has no events";
      const hasEvents = this.events.length > 0;

      if (hasEvents && this.error == errorMessage) {
        this.error = "";
      }

      if (!hasEvents) {
        this.error = errorMessage;
      }

      return hasEvents;
    },
    eventsHaveFields() {
      const eventsWithNoFields = this.events.filter(
        (event) => event.fields.length == 0
      );
      const eventsHaveFields = eventsWithNoFields.length == 0;

      if (!eventsHaveFields) {
        this.error = `Event ${eventsWithNoFields[0].name} has no fields`;
      }

      return eventsHaveFields;
    },
    submitData() {
      const hasEvents = this.hasEvents();
      const eventsHaveFields = hasEvents ? this.eventsHaveFields() : true;
      const isFormValid = this.$refs.form.validate();

      if (isFormValid && hasEvents && eventsHaveFields) {
        const convertArrayToObject = (array, key) => {
          const initialValue = {};
          return array.reduce((obj, item) => {
            return {
              ...obj,
              [item[key]]: item,
            };
          }, initialValue);
        };

        const config = {};

        const events = this.events.map((event) => {
          const { name, fields } = event;
          const config = {};

          if (name === undefined) {
            return false;
          }

          const data = fields.map((field) => {
            const {
              fieldName,
              required,
              validations,
              length,
              sequence_number,
            } = field;
            if (fieldName === undefined) {
              return false;
            }

            const field_data = {};

            field_data.name = fieldName;
            field_data.nullable = !required;

            if (length && sequence_number) {
              field_data.text_file_config = {
                length: parseInt(length),
                sequence_number: parseInt(sequence_number),
              };
            }

            if (validations) {
              field_data.validations = validations.map((validation) => {
                const { name, operator, input, validation_type } = validation;
                const validation_data = {};

                validation_data.name = name;
                validation_data.validation_type = validation_type;
                validation_data.operand = input;
                validation_data.operator = operator;

                if (validation_type == "number_size_check") {
                  validation_data.operand = parseInt(input);
                }

                if (validation_type == "type_check") {
                  validation_data.expected_type = operator;
                }

                return validation_data;
              });
            }

            return field_data;
          });

          config.name = name;
          config.has_tenant_callback_url = false;
          config.tenant_callback_url = "";
          config.data_fields = [
            {
              name: "bmm_dex_product_id",
              nullable: false,
              validations: [
                {
                  name: "Must not be blank",
                  validation_type: "blankness_check",
                },
              ],
            },
            ...data,
          ];

          return config;
        });

        config.name = this.data.name;
        config.accepts_structured_text_file =
          this.data.accepts_structured_text_file !== undefined ? true : false;
        config.events = convertArrayToObject(events, "name");

        ///console.log(JSON.stringify(config));

        this.$store.dispatch("saveProduct", config);
      }
    },
  },
};
</script>

<style></style>
