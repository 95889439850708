import axios from "axios";
import firebase from "firebase/app";

export default {
    state: {
        apiKey: "",
        apiKeyChanging: ""
    },
    mutations: {
        setApiKey(state, payload) {
            state.apiKey = payload;
        },
        setApiKeyChanging(state, payload) {
            state.apiKeyChanging = payload;
        }
    },
    getters: {
        getApiKey: state => state.apiKey,
        getApiKeyChanging: state => state.apiKeyChanging
    },
    actions: {
        async fetchApiKey({ commit, state }) {
            const tenantId = firebase.auth().tenantId;

            if (tenantId && !state.apiKey) {
                const db = firebase.firestore();
                const tenant_doc = db.collection("tenants").doc(tenantId);
                const tenant_info = await tenant_doc.get();
                const data = tenant_info.data();
                console.log(data)
                commit("setApiKey", data.api_key);
            }
        },
        async createApiKey({ commit, rootGetters, rootState }) {
            console.log("Create api key");
            if (rootGetters.isAdmin) {
                commit("setApiKeyChanging", true);
                const user = firebase.auth().currentUser;
                const tenant_id = rootState.tenantId;
                const url = `${process.env.VUE_APP_API_KEY_CREATOR_URL}/${tenant_id}`;
                console.log(`DEBUG: the api key url is ${url}`);
                user
                    .getIdToken(true)
                    .then(async function (token) {
                        const headers = {
                            Authorization: token.toString(),
                            "Content-Type": "application/json"
                        };
                        const response = await axios.post(url, {}, {
                            headers: headers
                        });
                        console.log("DEBUG: new api key is => " + response.data);
                        commit("setApiKey", response.data.api_key);
                        commit("setApiKeyChanging", false);
                    })
                    .catch(error => {
                        console.log(error);
                        commit("setApiKeyChanging", false);
                    });
            }
        },
        async refreshApiKey({ commit, rootGetters, rootState }) {
            if (rootGetters.isAdmin) {
                commit("setApiKeyChanging", true);
                const user = firebase.auth().currentUser;
                const tenant_id = rootState.tenantId;
                const url = `${process.env.VUE_APP_API_KEY_CREATOR_URL}/${tenant_id}`;
                console.log(`DEBUG: the api key url is ${url}`);
                user
                    .getIdToken(true)
                    .then(async function (token) {
                        const headers = {
                            Authorization: token.toString(),
                            "Content-Type": "application/json"
                        };
                        const response = await axios.patch(url, {}, {
                            headers: headers
                        });
                        console.log("DEBUG: refreshed api key is => " + response.data);
                        commit("setApiKey", response.data.api_key);
                        commit("setApiKeyChanging", false);
                    })
                    .catch(error => {
                        console.log(error);
                        commit("setApiKeyChanging", false);
                    });
            }
        },
    }
}