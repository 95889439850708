<template>
  <div>
    <div class="shadow sm:rounded-md sm:overflow-hidden">
      <div class="flex px-4 py-5 bg-white border-b border-gray-200 sm:p-6">
        <h3 class="text-lg font-medium text-gray-900">
          {{ $t("tenant.page.data.title") }}
        </h3>
      </div>
      <div class="pb-5 space-y-6 bg-white">
        <v-data-table
          :loading="getLoadingProducts"
          :loading-text="$t('tenant.page.data.progress')"
          :headers="headers"
          :items="getProducts"
          :items-per-page="30"
          flat
        >
          <template v-slot:item.subscriptions="{ item }">
            <v-btn
              color="primary"
              outlined
              small
              v-on:click="openSubdialogue(item.id, item.name)"
            >
              {{ $t("tenant.page.data.headers.subscriptions") }}
            </v-btn>
          </template>
        </v-data-table>
      </div>
    </div>
    <v-dialog v-model="subdialog" max-width="90vw">
      <v-data-table
        :headers="subscriptionHeaders"
        :loading="getProductSubsLoading"
        :loading-text="$t('tenant.page.data.progress')"
        :items="getProductSubs"
      >
        <template v-slot:item.prod_name>
          {{ selectedProduct }}
        </template>

        <template v-slot:item.data="{ item }">
          <router-link
            :to="{
              name: 'SubscriptionSubmissions',
              params: { subscription_id: `${item.subscription_id}` },
            }"
          >
            {{ $t("tenant.page.data.subscriptionheaders.data") }}
          </router-link>
        </template>
      </v-data-table>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {},
  data() {
    return {
      selectedProduct: "",
      subdialog: false,
    };
  },
  methods: {
    openSubdialogue(product_id, product_name) {
      this.selectedProduct = product_name;
      this.$store.dispatch("getProductSubs", product_id);
      this.subdialog = true;
    },
  },
  computed: {
    ...mapGetters([
      "getProducts",
      "getLoadingProducts",
      "getProductSubsLoading",
      "getProductSubs",
    ]),
    subscriptionHeaders() {
      return [
        {
          text: this.$t("tenant.page.data.subscriptionheaders.customer"),
          value: "customer_name",
        },
        {
          text: this.$t("tenant.page.data.subscriptionheaders.product"),
          value: "prod_name",
        },
        {
          text: this.$t("tenant.page.data.subscriptionheaders.data"),
          value: "data",
        },
      ];
    },
    headers() {
      return [
        {
          text: this.$t("tenant.page.data.headers.productname"),
          value: "name",
        },
        {
          text: this.$t("tenant.page.data.headers.subscriptions"),
          value: "subscriptions",
          sortable: false,
        },
      ];
    },
  },
};
</script>

<style></style>
