var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        fullscreen: "",
        "hide-overlay": "",
        transition: "dialog-bottom-transition"
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var attrs = ref.attrs
            return [
              _c(
                "v-btn",
                _vm._b(
                  {
                    attrs: { color: "primary", dark: "" },
                    on: { click: _vm.showDialog }
                  },
                  "v-btn",
                  attrs,
                  false
                ),
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("tenant.page.products.new.title")) + " "
                  )
                ]
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.getDialog,
        callback: function($$v) {
          _vm.getDialog = $$v
        },
        expression: "getDialog"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "grey lighten-4" },
        [
          _c(
            "v-toolbar",
            { attrs: { dark: "", color: "primary" } },
            [
              _c(
                "v-btn",
                {
                  attrs: { icon: "", dark: "" },
                  on: { click: _vm.closeDialog }
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
              _c("v-toolbar-title", [
                _vm._v(_vm._s(_vm.$t("tenant.page.products.new.subtitle")))
              ])
            ],
            1
          ),
          _c("ProductForm")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }