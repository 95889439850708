var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ma-4", attrs: { app: "" } },
    [
      _c("v-container", { staticClass: "full-height", attrs: { fluid: "" } }, [
        _c("div", { staticClass: "shadow sm:rounded-md sm:overflow-hidden" }, [
          _c(
            "div",
            {
              staticClass:
                "flex px-4 py-5 bg-white border-b border-gray-200 sm:p-6"
            },
            [
              _c("h3", { staticClass: "text-lg font-medium text-gray-900" }, [
                _vm._v(" " + _vm._s(_vm.$t("common.page.users.users")) + " ")
              ]),
              _c("v-spacer"),
              _vm.isAdmin
                ? _c(
                    "router-link",
                    {
                      staticClass:
                        "px-2 py-1 text-sm tracking-wider uppercase border rounded primary--text border-primary focus:bg-blue-50",
                      attrs: { to: "/users/new" }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("common.page.users.createuser")) +
                          " "
                      )
                    ]
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "pb-5 space-y-6 bg-white" },
            [
              _c("v-data-table", {
                attrs: {
                  loading: _vm.getLoadingUsers,
                  "loading-text": _vm.$t("common.page.users.loadingtext"),
                  headers: _vm.headers,
                  items: _vm.getUsers,
                  "items-per-page": 30,
                  flat: ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "item.actions",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _vm.isAdmin
                          ? _c(
                              "div",
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "mr-2",
                                    attrs: { small: "" },
                                    on: {
                                      click: function($event) {
                                        return _vm.openDialog(item)
                                      }
                                    }
                                  },
                                  [_vm._v("mdi-pencil")]
                                ),
                                !_vm.isCurrentUserData(item) && _vm.isAdmin
                                  ? _c(
                                      "v-icon",
                                      {
                                        attrs: { small: "" },
                                        on: {
                                          click: function($event) {
                                            return _vm.deleteUser(item)
                                          }
                                        }
                                      },
                                      [_vm._v("mdi-delete")]
                                    )
                                  : _vm._e(),
                                _vm.editing_user &&
                                _vm.editing_user.id == item.id
                                  ? _c(
                                      "v-dialog",
                                      {
                                        attrs: { "max-width": "800" },
                                        model: {
                                          value: _vm.dialog,
                                          callback: function($$v) {
                                            _vm.dialog = $$v
                                          },
                                          expression: "dialog"
                                        }
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "w-full p-2 bg-white"
                                          },
                                          [
                                            _c(
                                              "h4",
                                              { staticClass: "mb-6 text-2xl" },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "common.page.users.updateuser"
                                                      )
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            ),
                                            _c(
                                              "v-form",
                                              {
                                                ref: "userdata",
                                                attrs: { "lazy-validation": "" }
                                              },
                                              [
                                                _c("v-text-field", {
                                                  attrs: {
                                                    label: _vm.$t(
                                                      "common.name"
                                                    ),
                                                    rules: _vm.nameRules,
                                                    outlined: ""
                                                  },
                                                  model: {
                                                    value: _vm.display_name,
                                                    callback: function($$v) {
                                                      _vm.display_name = $$v
                                                    },
                                                    expression: "display_name"
                                                  }
                                                }),
                                                !_vm.isCurrentUserData(item) &&
                                                _vm.isAdmin
                                                  ? _c(
                                                      "v-radio-group",
                                                      {
                                                        staticClass: "ma-4",
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "label",
                                                              fn: function() {
                                                                return [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "text-lg text-gray-800"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "common.page.users.role"
                                                                            )
                                                                          ) +
                                                                          " "
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              },
                                                              proxy: true
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        ),
                                                        model: {
                                                          value: _vm.role,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.role = $$v
                                                          },
                                                          expression: "role"
                                                        }
                                                      },
                                                      _vm._l(
                                                        _vm.roles,
                                                        function(
                                                          description,
                                                          role,
                                                          index
                                                        ) {
                                                          return _c("v-radio", {
                                                            key: index,
                                                            attrs: {
                                                              label: description,
                                                              value: role
                                                            }
                                                          })
                                                        }
                                                      ),
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "flex justify-end"
                                                  },
                                                  [
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        attrs: {
                                                          color:
                                                            "red lighten-1",
                                                          text: ""
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                            $event.preventDefault()
                                                            _vm.dialog = false
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "common.cancel"
                                                              )
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        attrs: {
                                                          color:
                                                            "green darken-1",
                                                          text: ""
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                            $event.preventDefault()
                                                            return _vm.updateUserClaims(
                                                              $event
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "common.update"
                                                              )
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          : _vm._e()
                      ]
                    }
                  },
                  {
                    key: "item.role",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        item.role != null
                          ? _c("span", [_vm._v(_vm._s(_vm.userRole(item)))])
                          : _c("span", [_vm._v("No role")])
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }