import Vue from "vue";
import Vuex from "vuex";
import usersModule from "../views/Users/Store/index";
import productsModule from "../views/Products/Store/index";
import distributorModule from "../views/Distributors/Store/index";
import submissionModule from "../views/Submissions/Store/index";
import SubscriptionSubmissionModule from "../views/SubscriptionSubmissions/Store";
import SessionsModule from "../views/Sessions/Store";
// import DashboardModule from "../views/Dashboard/Store";
import dashboardModule from "../views/Dashboard/Store/index";
import ProductDetailsModule from "../views/ProductDetails/Store";
import ApiKeyModule from "../views/Api/Store";
import { isAdmin, isViewer } from "../../../utils/roles.js";
import firebase from "firebase/app";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    drawerStatus: true,
    currentUser: null,
    // TODO: replace currentUser with below
    // currentUser: firebase.auth().currentUser,
    tenantId: "",
    currentBmmUser: null,
    tenantDetails: null
  },
  mutations: {
    setCurrentUser(state, payload) {
      state.currentUser = payload;
    },
    setDrawerStatus(state, payload) {
      state.drawerStatus = payload;
    },
    setTenantId(state, payload) {
      state.tenantId = payload;
    },
    setcurrentBmmUser(state, payload) { state.currentBmmUser = payload; },
    setTenantDetails(state, payload) { state.tenantDetails = payload; }
  },
  actions: {
    // authAction({ commit, dispatch, state }) {
    authAction({ dispatch }) {
      dispatch("getTenantID");
    },
    async prepStoreWithAuthValues({ commit, dispatch, state }) {
      firebase.auth().tenantId = state.tenantId;
      const user = firebase.auth().currentUser;
      if (user) {
        commit("setCurrentUser", user);
        const tokenResult = await user.getIdTokenResult();
        commit("setcurrentBmmUser", { id: user.uid, role: tokenResult.claims });
        commit("setDrawerStatus", true);
      } else {
        commit("setCurrentUser", null);
        commit("setDrawerStatus", false);
      }
    },
    async getTenantID({ commit, dispatch }) {
      const hosturl = window.location.host;
      const subdomain = hosturl.split(".")[0];
      /*
       * TODO: Have a safer way of checking and limiting tenant names.
       * Check for for 'admin' early and act accordingly
       * Redirect for 'www' and other reserved or blocked tenant names
       * What character to allow or restrict in tenant names
       */
      const db = firebase.firestore();

      try {
        const snapshot = await db
          .collection("tenants")
          .where("subdomain", "==", subdomain)
          .get();

        if (snapshot.empty) {
          commit("setTenantId", "admin");
          return;
        }

        let docs = []
        snapshot.forEach(doc => {
          docs.push(doc);
        });

        const data = docs[0].data();
        const tenant_id = data.tenant_id;
        commit("setTenantDetails", data);
        commit("setTenantId", tenant_id);
        dispatch('prepStoreWithAuthValues');
      } catch (err) {
        // TODO: Redirect user to home page when tenant not found
        commit("setTenantId", "admin");
      }

    }
  },
  modules: {
    usersModule,
    productsModule,
    distributorModule,
    submissionModule,
    SubscriptionSubmissionModule,
    SessionsModule,
    // DashboardModule,
    dashboardModule,
    ProductDetailsModule,
    ApiKeyModule
  },
  getters: {
    getDrawerStatus: state => state.drawerStatus,
    getTenantID: state => state.tenantId,
    getTenantDetails: state => state.tenantDetails,
    isCurrentUserData: (state) => (user) => { return firebase.auth().currentUser.email === user.email },
    isAdmin: state => { return state.currentBmmUser ? isAdmin(state.currentBmmUser) : false },
    isViewer: state => { return state.currentBmmUser ? isViewer(state.currentBmmUser) : true }
  }
});
