import axios from "axios";
import firebase from "firebase/app";
import router from "../../../router"

export default {
  state: {
    users: [],
    loadingUsers: false,
    error: "",
  },
  actions: {
    getUsers({ commit, rootState }) {
      commit("setLoadingUsers", true);
      const user = firebase.auth().currentUser;
      // const user = rootState.currentUser;
      const tenant_id = rootState.tenantId;
      var url = `${process.env.VUE_APP_API_URL}/get_users`;
      user
        .getIdToken(true)
        .then(async function (token) {
          const headers = {
            Authorization: token.toString(),
            "Content-Type": "application/json"
          };
          if (tenant_id != "admin") {
            url = url + "?tenant_id=" + tenant_id;
          }
          const response = await axios.get(url, {
            headers: headers
          });
          console.log(response.data);
          var tenantUsers = response.data.filter(function (user) {
            if (user.role != null) {
              return user.role.isTenantUser;
            }
          });
          // console.log("The Insurer users");
          // console.log(tenantUsers);
          commit("setUsers", tenantUsers);
        })
        .catch(error => {
          console.log(error);
          commit("setUsers", []);
        });
    },
    createUser({ dispatch, commit, rootState, rootGetters }, payload) {
      if (rootGetters.isAdmin) {
        commit("setLoadingUsers", true);
        if (rootState.tenantId != "admin") {
          payload.tenant_id = rootState.tenantId;
        }
        console.log(payload);
        const url = `${process.env.VUE_APP_API_URL}/create_tenant_user`;
        const user = firebase.auth().currentUser;
        user
          .getIdToken(true)
          .then(async function (token) {
            const headers = {
              Authorization: token.toString(),
              "Content-Type": "application/json",
              Accept: "application/json"
            };

            axios.post(url, payload, { headers: headers })
              .then(response => {
                router.push({ path: "/users" });
              })
              .catch(error => {
                if (error.response) {
                  commit("setUserError", error.response.data["Error"])
                }
                commit("setLoadingUsers", false);
              })
          })
          .catch(error => {
            console.log(error);
          });
      }
    },
    deleteUser({ dispatch, commit, rootState, rootGetters }, payload) {
      if (rootGetters.isAdmin) {
        commit("setLoadingUsers", true);
        if (rootState.tenantId != "admin") {
          payload.tenant_id = rootState.tenantId;
        }
        console.log(payload);
        const url = `${process.env.VUE_APP_API_URL}/delete_user`;
        const user = firebase.auth().currentUser;
        user
          .getIdToken(true)
          .then(async function (token) {
            const headers = {
              Authorization: token.toString(),
              "Content-Type": "application/json",
              Accept: "application/json"
            };
            const response = await axios.post(url, payload, {
              headers: headers
            });
            if (response.status == "200") {
              dispatch("getUsers");
            }
          })
          .catch(error => {
            console.log(error);
          });
      }
    },
    updateUserClaims({ dispatch, commit, rootState, rootGetters }, payload) {
      commit("setLoadingUsers", true);
      if (rootState.tenantId != "admin") {
        payload.tenant_id = rootState.tenantId;
      }
      const url = `${process.env.VUE_APP_API_URL}/update_user_claims`;
      const user = firebase.auth().currentUser;
      user
        .getIdToken(true)
        .then(async function (token) {
          const headers = {
            Authorization: token.toString(),
            "Content-Type": "application/json",
            Accept: "application/json"
          };
          const response = await axios.post(url, payload, {
            headers: headers
          });
          if (response.status == "200") {
            dispatch("getUsers");
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
  },
  mutations: {
    setUsers(state, payload) {
      state.users = payload;
      state.loadingUsers = false;
    },
    setLoadingUsers(state, payload) {
      state.loadingUsers = payload;
    },
    setUserError(state, payload) {
      state.error = payload;
    }
  },
  getters: {
    getUsers: state => state.users,
    getLoadingUsers: state => state.loadingUsers,
    getInsurerUsers: function (state) {
      state.users.filter(function (user) {
        console.log(user);
        return user;
      });
    },
    getUserError: state => state.error
  }
};
