<template>
  <div class="ma-4" app>
    <v-container fluid class="full-height">
      <v-row>
        <DataRow />
      </v-row>
      <v-row>
        <v-col md="4">
          <div class="shadow sm:rounded-md sm:overflow-hidden">
            <div
              class="px-4 py-5 space-y-6 bg-white border-b border-gray-200 sm:p-6"
            >
              <h3 class="text-lg font-medium text-gray-900">
                {{ $t("tenant.page.dashboard.customers.title") }}
              </h3>
            </div>
            <div class="pb-5 space-y-6 bg-white">
              <CustomerTable />
            </div>
            <div class="px-4 py-3 text-right bg-gray-50 sm:px-6">
              <router-link
                to="distributors"
                class="font-semibold text-teal-500 transition duration-150 ease-in-out hover:text-teal-600"
              >
                {{ $t("tenant.page.dashboard.customers.more") }}
              </router-link>
            </div>
          </div>
        </v-col>
        <v-col md="4">
          <div class="shadow sm:rounded-md sm:overflow-hidden">
            <div
              class="px-4 py-5 space-y-6 bg-white border-b border-gray-200 sm:p-6"
            >
              <h3 class="text-lg font-medium text-gray-900">
                {{ $t("tenant.page.dashboard.products.title") }}
              </h3>
            </div>
            <div class="pb-5 space-y-6 bg-white">
              <ProductsTable />
            </div>
            <div class="px-4 py-3 text-right bg-gray-50 sm:px-6">
              <router-link
                to="products"
                class="font-semibold text-teal-500 transition duration-150 ease-in-out hover:text-teal-600"
              >
                {{ $t("tenant.page.dashboard.products.more") }}
              </router-link>
            </div>
          </div>
        </v-col>
        <v-col md="4">
          <div class="shadow sm:rounded-md sm:overflow-hidden">
            <div
              class="px-4 py-5 space-y-6 bg-white border-b border-gray-200 sm:p-6"
            >
              <h3 class="text-lg font-medium text-gray-900">
                {{ $t("tenant.page.dashboard.data.title") }}
              </h3>
            </div>
            <div class="pb-5 space-y-6 bg-white">
              <SessionsTable />
            </div>
            <div class="px-4 py-3 text-right bg-gray-50 sm:px-6">
              <router-link
                to="data"
                class="font-semibold text-teal-500 transition duration-150 ease-in-out hover:text-teal-600"
              >
                {{ $t("tenant.page.dashboard.data.more") }}
              </router-link>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import ProductsTable from "./Components/ProductsTable";
import SessionsTable from "./Components/SessionsTable";
import CustomerTable from "./Components/CustomerTable";
import DataRow from "./Components/DataRow";

export default {
  components: {
    ProductsTable,
    SessionsTable,
    CustomerTable,
    DataRow,
  },
  mounted() {
    this.$store.dispatch("getDashboardDataTenant");
  },
};
</script>

<style></style>
