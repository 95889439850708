var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { attrs: { justify: "end" } },
    [
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "600px" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                var attrs = ref.attrs
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        { attrs: { color: "primary", dark: "" } },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("tenant.page.customers.new.title")) +
                          " "
                      )
                    ]
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _vm._v(_vm._s(_vm.$t("tenant.page.customers.new.title")))
              ]),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-form",
                    { ref: "form", attrs: { "lazy-validation": "" } },
                    [
                      _c(
                        "section",
                        [
                          _c(
                            "h3",
                            { staticClass: "mb-4 text-xl text-gray-800" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("tenant.page.customers.new.customer")
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c("v-text-field", {
                            attrs: {
                              label: _vm.$t("tenant.page.customers.new.name"),
                              rules: _vm.nameRules,
                              required: "",
                              outlined: ""
                            },
                            model: {
                              value: _vm.customer_name,
                              callback: function($$v) {
                                _vm.customer_name = $$v
                              },
                              expression: "customer_name"
                            }
                          }),
                          _c("v-text-field", {
                            attrs: {
                              label: _vm.$t(
                                "tenant.page.customers.new.identifier"
                              ),
                              hint: _vm.$t(
                                "tenant.page.customers.new.identifierhint"
                              ),
                              outlined: "",
                              "persistent-hint": true
                            },
                            model: {
                              value: _vm.identifier,
                              callback: function($$v) {
                                _vm.identifier = $$v
                              },
                              expression: "identifier"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "section",
                        [
                          _c(
                            "h3",
                            { staticClass: "mb-4 text-xl text-gray-800" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("tenant.page.customers.new.admin")
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c("v-text-field", {
                            attrs: {
                              label: _vm.$t("tenant.page.customers.new.name"),
                              rules: _vm.nameRules,
                              required: "",
                              outlined: ""
                            },
                            model: {
                              value: _vm.customer_admin_name,
                              callback: function($$v) {
                                _vm.customer_admin_name = $$v
                              },
                              expression: "customer_admin_name"
                            }
                          }),
                          _c("v-text-field", {
                            attrs: {
                              label: _vm.$t("tenant.page.customers.new.email"),
                              required: "",
                              outlined: "",
                              rules: _vm.emailRules
                            },
                            model: {
                              value: _vm.email,
                              callback: function($$v) {
                                _vm.email = $$v
                              },
                              expression: "email"
                            }
                          }),
                          _c("v-text-field", {
                            attrs: {
                              type: "password",
                              label: _vm.$t(
                                "tenant.page.customers.new.password"
                              ),
                              required: "",
                              outlined: "",
                              rules: _vm.passwordRules
                            },
                            model: {
                              value: _vm.password,
                              callback: function($$v) {
                                _vm.password = $$v
                              },
                              expression: "password"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "flex justify-end space-x-3" },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                color: "grey darken-1",
                                text: "",
                                small: ""
                              },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  return _vm.closeDialog($event)
                                }
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("tenant.page.customers.new.cancel")
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                color: "blue darken-1",
                                outlined: "",
                                small: ""
                              },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  return _vm.createUser($event)
                                }
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("tenant.page.customers.new.title")
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }