<template>
  <div>
    <v-card outlined>
      <v-card-title>
        <v-col cols="10">{{ $t("tenant.page.session.title") }}</v-col>
        <v-col cols="1">
          <v-btn
            color="primary"
            justify="end"
            @click="getData"
            :disabled="downloaded"
          >
            {{ $t("tenant.page.session.buttons.download") }}
          </v-btn>
        </v-col>
      </v-card-title>
      <v-card-text>
        <v-simple-table flat>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  {{ $t("tenant.page.session.headers.product") }}
                </th>
                <th class="text-left">
                  {{ $t("tenant.page.session.headers.row") }}
                </th>
                <th class="text-left">
                  {{ $t("tenant.page.session.headers.rowerrors") }}
                </th>
                <th class="text-left">
                  {{ $t("tenant.page.session.headers.reads") }}
                </th>
                <th class="text-left">
                  {{ $t("tenant.page.session.headers.downloaded") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ session.product_name }}</td>
                <td>{{ $n(session.total_document_count) }}</td>
                <td>{{ $n(session.failed_validation_document_count) }}</td>
                <td>{{ $n(session.reads) }}</td>
                <td>{{ session.downloaded }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>

    <v-card class="my-4" outlined>
      <v-card-title>
        <v-row class="mx-4">
          {{ $t("tenant.page.session.datasession") }}
          <v-spacer></v-spacer>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th
                  class="text-left"
                  v-for="(header, index) in getHeaders"
                  :key="index"
                >
                  {{ header }}
                </th>
              </tr>
              <tr class="p-0 m-0" v-if="getLoadingSessionData">
                <td class="p-0 m-0" :colspan="getHeaders.length">
                  <v-progress-linear indeterminate></v-progress-linear>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="event in getSessionData"
                :key="event.bmm_dex_session_event_id"
              >
                <td
                  v-for="(header, index) in getHeaders"
                  :key="index"
                  :class="{
                    'text-red-600': event.bmm_dex_has_failed_validation,
                  }"
                >
                  <span class="block">{{ event[header].value }}</span>
                  <span class="block my-1 text-sm">{{
                    event[header].message
                  }}</span>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td :colspan="getHeaders.length">
                  <div class="flex justify-end space-x-4">
                    <v-btn
                      outlined
                      small
                      color="grey"
                      @click="
                        downloadSessionEvents({ before: getPagination.before })
                      "
                      v-if="getPagination.before"
                    >
                      {{ $t("$vuetify.pagination.ariaLabel.previous") }}
                    </v-btn>
                    <v-btn
                      outlined
                      small
                      color="grey"
                      @click="
                        downloadSessionEvents({ after: getPagination.after })
                      "
                      v-if="getPagination.after"
                    >
                      {{ $t("$vuetify.pagination.ariaLabel.next") }}
                    </v-btn>
                  </div>
                </td>
              </tr>
            </tfoot>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
export default {
  data() {
    return {
      dialog: false,
      showData: false,
      session: null,
    };
  },
  beforeMount() {
    this.$store.commit("setLoadingSessionData", true);
    console.log("Is session Loading");
    console.log(this.getLoadingSessionData);
    console.log("Session Data Here");
    console.log(this.$store.getters.getCurrentSession);
    this.downloadSessionEvents({});
    this.showData = true;
    this.session = this.getCurrentSession[0];
  },
  methods: {
    downloadSessionEvents(payload) {
      this.$store.commit("setLoadingSessionData", true);
      (payload.ref_id = this.$route.params.id),
        this.$store.dispatch("getSessionData", payload);
    },
    async getData() {
      const id = this.$route.params.id;
      var setSessions = this.$store.getters.getCurrentSession;
      var currentSession = setSessions[0];
      const collection = currentSession.collection;
      const file_name = id + "-" + collection + "-data.csv";
      const user = this.$store.state.currentUser;
      const tenantId = this.$store.state.tenantId;

      var url = `${process.env.VUE_APP_API_URL}/get_session_events_csv_download`;
      user
        .getIdToken(true)
        .then(async function (token) {
          const headers = {
            Authorization: token.toString(),
            "Content-Type": "application/json",
          };
          var hosturl = window.location.host;
          var subdomain = hosturl.split(".")[0];
          url = url + "?subdomain=" + subdomain;
          url = url + "&tenant_id=" + tenantId;
          url = url + "&bmm_dex_session_id=" + id;

          console.log(`DEBUG: SessionTable.vue - url => ${url}`);

          const response = await axios.get(url, { headers: headers });
          const Downurl = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = Downurl;
          link.setAttribute("download", file_name);
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  computed: {
    ...mapGetters([
      "getSessionData",
      "getLoadingSessionData",
      "getSessions",
      "getCurrentSessionCollection",
      "getCurrentSession",
      "getUserClaims",
      "getHeaders",
      "getPagination",
    ]),
  },
};
</script>

<style></style>
