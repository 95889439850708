var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "max-w-xl px-2 py-4 mx-auto mt-3 bg-white rounded shadow" },
    [
      _c("h2", { staticClass: "my-4 text-xl" }, [
        _vm._v(_vm._s(_vm.$t("common.page.users.createuser")))
      ]),
      _c(
        "v-form",
        { ref: "form", attrs: { "lazy-validation": "" } },
        [
          _c("v-text-field", {
            attrs: {
              label: _vm.$t("common.name"),
              rules: _vm.nameRules,
              required: "",
              outlined: ""
            },
            model: {
              value: _vm.name,
              callback: function($$v) {
                _vm.name = $$v
              },
              expression: "name"
            }
          }),
          _c("v-text-field", {
            attrs: {
              label: _vm.$t("common.email"),
              error: this.getUserError ? true : false,
              "error-messages": this.getUserError,
              required: "",
              outlined: "",
              rules: _vm.emailRules
            },
            model: {
              value: _vm.email,
              callback: function($$v) {
                _vm.email = $$v
              },
              expression: "email"
            }
          }),
          _c("v-text-field", {
            attrs: {
              label: _vm.$t("common.password"),
              type: "password",
              rules: _vm.passwordRules,
              required: "",
              outlined: ""
            },
            model: {
              value: _vm.password,
              callback: function($$v) {
                _vm.password = $$v
              },
              expression: "password"
            }
          }),
          _c(
            "v-radio-group",
            {
              attrs: {
                label: _vm.$t("common.page.users.role"),
                value: "Viewer",
                mandatory: "",
                row: ""
              },
              model: {
                value: _vm.role,
                callback: function($$v) {
                  _vm.role = $$v
                },
                expression: "role"
              }
            },
            _vm._l(_vm.roles, function(description, role, index) {
              return _c("v-radio", {
                key: index,
                attrs: { label: description, value: role }
              })
            }),
            1
          ),
          _c(
            "div",
            { staticClass: "flex justify-end space-x-3" },
            [
              _c(
                "router-link",
                { attrs: { to: "/users" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "grey darken-1",
                        type: "button",
                        text: "",
                        small: ""
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("common.cancel")) + " ")]
                  )
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "blue darken-1",
                    type: "submit",
                    outlined: "",
                    small: ""
                  },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      return _vm.createUser($event)
                    }
                  }
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("common.page.users.createuser")) + " "
                  )
                ]
              )
            ],
            1
          ),
          _vm.getLoadingUsers
            ? _c("v-progress-linear", {
                staticClass: "mt-2",
                attrs: { indeterminate: "", color: "blue" }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }